import axios from 'axios';
import {
    obtenerValorConfig
} from '../utilities/styles/configStyle';
import { generateHeaders } from './configuration-api/configuration';

const host = process.env.REACT_APP_URL //https://api.qadicio.net:8444
const servicio = process.env.REACT_APP_SERVICE //nqa_
const correoH = window.location.hostname;

const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    otorgantePublic: `${host}/v1/sec_${servicio}public_params`,
    otorgantePK: `${host}/v1/sec_${servicio}params_pk`,
    ocr: `${host}/v2/sec_${servicio}ocr`,
    nom151: `${host}/v1/sec_${servicio}async_zip_nom_151_correo`,
    score: `${host}/v2/sec_${servicio}query_score`,
    actualizarScore: `${host}/v2/sec_${servicio}score_update`,
    actualizarEstado: `${host}/v2/sec_${servicio}update_cycle`,
    getDocuments: `${host}/v1/sec_${servicio}proccessdocuments`,
    sendEvent: `${host}/v1/sec_${servicio}events`,
    evaluateImage: `${host}/v1/sec_${servicio}h_prediction/classify`,
    sendImageOCR: `${host}/v1/sec_${servicio}extraction`,
};

let CancelToken = axios.CancelToken;
let cancel = () => {};

export function actualizarEstado(objeto, configuration) {
    let data = JSON.stringify(objeto);
    let headers = generateHeaders(configuration, 'json');
    return axios({
        method: 'post',
        url: endpoints.actualizarEstado,
        headers: headers,
        data: data
    });
}

export function obtenerParametrosOtorgante(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerParametrosOtorgantePK(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePK,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function enviarCorreoAceptacion(data) {
    return axios({
        method: 'post',
        url: endpoints.correo,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'mail-host': correoH,
            'Accept': 'application/json'
        },
        data: data
    });
}


export function actualizarScore(uuid, calle, colonia, cp, estado, municipio, numeroExt, gps, empresa) {
    var data = JSON.stringify({
        "update": "true",
        "uuid": uuid,
        "transaccion": localStorage.getItem("uuidTrx"),
        //"otorgante_uuid": localStorage.getItem("uuidOtorgante"),
        //"otorgante_name": empresa,
        "calle": calle,
        "colonia": colonia,
        "cp": cp,
        "estado": estado,
        "municipio": municipio,
        "numero": numeroExt,
        "gps": gps,
        "isDesktop": true
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: data
    });
}

export function obtenerScore(uuid, isDesktop) {
    let dataOtorgante = JSON.parse(localStorage.getItem("dataOtorgante"));
    let nombreOtorgante = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""));
    return axios({
        method: 'get',
        url: endpoints.score + '/' + uuid + '/' + localStorage.getItem("uuidTrx"),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'X-Consumer-Custom-ID': localStorage.getItem("uuidOtorgante"),
            'X-Consumer-Username': nombreOtorgante
        },
        params: {
            isDesktop: isDesktop
        }
    });
}


export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const getImageDimensions = file => new Promise((resolved, rejected) => {
    var i = new Image();
    i.onload = function () {
        resolved({
            w: i.width,
            h: i.height
        });
    };
    i.src = file;
});


/* DOCUMENTOS */
export function getDocuments() {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        data: {
            uuidUser: localStorage.getItem("uuidUser")
        }
    })
}

export function finalUpdateScore(objeto) {
    let uuidTrx = localStorage.getItem("uuidTrx");
    let cliente = localStorage.getItem("uuidUser");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    objeto.uuid = cliente;
    objeto.transaccion = uuidTrx;
    let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': uuidTrx,
            'cliente': cliente,
            'apikey': apikeyOtorgante
        },
        crossdomain: true,
        data: data
    });
}

export function actualizarScoreInicioFlujo() {
    let uuid =  localStorage.getItem('uuidUser');
    let transaccion = localStorage.getItem('uuidTrx');
    let otorgante_uuid = localStorage.getItem('uuidOtorgante')
    let data = JSON.stringify({
        "uuid": uuid,
        "transaccion": transaccion,
        otorgante_uuid: otorgante_uuid,
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: data
    });
} 

export function cancelarRequest() {
    cancel();
}

export function obtenerParametrosOtorgantePublic(idOtorgante) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePublic+"/"+idOtorgante,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        }
    });
}

/* Eventos NocoDB */
export const enviarEvento = (objeto, data, newHeaders) => {
    let headers = generateHeaders(objeto, 'json', newHeaders);
    return axios({
        method: 'post',
        url: endpoints.sendEvent,
        headers: headers,
        data: data
    });
}
/*--- ---*/

/*--- Extracción OCR ---*/
export const enviarImagenOCR = (objeto, data, newHeaders) => {
    let headers = generateHeaders(objeto, 'form-data', newHeaders);
    return axios({
        method: 'post',
        url: endpoints.sendImageOCR,
        headers: headers,
        data: data,
    });
}

export function obtenerOCR(objeto,data) {
    let headers = generateHeaders(objeto, 'form-urlencoded');
    return axios({
        method: 'post',
        url: endpoints.ocr,
        headers: headers,
        data: data
    });
}
/*------*/

/*--- Clasificar imagen ---*/
export const enviarImagenEvaluar = (objeto, data) => {
    let headers = generateHeaders(objeto, 'form-data');
    return axios({
        method: 'POST',
        url: endpoints.evaluateImage,
        headers: headers,
        data: data
    });
}
/*------*/

/*--- Generación de archivo ZIP nom 151 ---*/
export function generacionNom151(objeto,data) {
    let headers = generateHeaders(objeto, 'json');
    return axios({
        method: 'post',
        url: endpoints.nom151,
        headers: headers,
        data: data
    });
}
/*------*/

/*--- Actualización del score ----*/
export function enviarActualizacionScore(objeto,data) {
    let headers = generateHeaders(objeto, 'json');
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: headers,
        crossdomain: true,
        data: JSON.stringify(data)
    });
}
