import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { isIOS, isMobile } from 'react-device-detect';
import { validarRegistro , obtenerStatus, obtenerTokeMidleWareCredencialesFaceTec, obtenerllavesFaceTec} from '../services/api';

import Loader from "../components/loader";
import LoaderOCR from "../components/loaderOCR";
import Header from "../components/header";
import { controladorFaceTec } from "../services/facetec/testControler.js";
import { configStyle, obtenerValorConfig } from '../services/configStyle.js';


const CapturePhoto = () => {
    const history = useNavigate();
    const [localStream, setLocalStream] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [errorVideo, setErrorVideo] = useState(false);
    const [aCuadro, setACuadro] = useState(false);
    const [estable, setEstable] = useState(false);

    const [blob, setBlob] = useState(null);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("No hemos podido validar tu identidad, por favor intentalo nuevamente.")
    const [photoTaken, setPhotoTaken] = useState(false);
    const [instrucciones, setInstrucciones] = useState(true);

    const [Gps, setGps] = useState('')
    const [errorUbicacion, setErrorUbicacion] = useState(false);
    const [errorCurp, setErrorCurp] = useState(false);
    const [focusCurp, setFocusCurp] = useState(false)

    const [facetecCargado, setFacetecCargado] = useState(false)
    const [textoBoton, setTextoBoton] = useState("Siguiente");
    const [imagenGuia, setImagenGuia] = useState(1);
    const [paso, setPaso] = useState(0);
    const [mostrarLaoder, setMostrarLaoder] = useState(true);
    const faceTec = useRef(null);
    const refCanvas = useRef(null);
    const refVideo = useRef(null);

    const facetecLoading = useRef(false)

    useEffect(() => {
        //if (refDivFooter.current) refDivFooter.current.style.position = positionDiv(window.innerWidth, window.innerHeight);
        setLoading(true)
        if (!facetecLoading.current) {
            iniciarFaceTec();
            facetecLoading.current = true;
        }
        return()=>{
            if (faceTec.current) {
                faceTec.current.limpiarFaceTec();
            }
        }
    }, []);
    const avanzar =()=>{
        if (paso == 0) {
            setPaso((prev)=>
                prev + 1
            );
            setTextoBoton("Muy bien, Capturar rostro");
            setImagenGuia(2)
        }
        else{
            iniciarPruebaVida();
        }
    }
    const iniciarFaceTec=async ()=>{
        try {
            let token = await  obtenerTokeMidleWareCredencialesFaceTec();
            let Facetec_data = await obtenerllavesFaceTec(token);
            faceTec.current = new controladorFaceTec(
             manejadorPruebaVida,
             (data)=>{console.log(data)
                 setError("Error durante la prueba de vida, por favor vuelva a intentar.")
                 setMostrarLaoder(false);
                 facetecLoading.current = false;
             }, 
             (data)=>{
                 setMostrarLaoder(false);
                 facetecLoading.current = false;
                 console.log(data);
                 if(data.paso==="iniciado")
                     setFacetecCargado(true);
                 else 
                     setError("Error al cargar Liveness");
             },
             Facetec_data.ProductionKey, Facetec_data.DeviceKeyIdentifier, 
             Facetec_data.PublicFaceScanEncryptionKey, Facetec_data.BaseURL,
             obtenerSVGLoader(), 
             obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_ACENTUACION","gray"),
             obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_DE_TEXTO","black"),
           );
           //faceTec.abiriLiveness();
        } catch (error) {
            console.log(error);
            setError("Problemas de conexión.")
            setMostrarLaoder(false);
        }
      
    }
    const obtenerSVGLoader =()=>{
        let color_interior = "white";
        let svgLoader = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgLoader.innerHTML=`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" preserveAspectRatio="xMidYMid" 
        width="60" height="60" style="shape-rendering: auto; fill: blue;" xmlns:xlink="http://www.w3.org/1999/xlink">
        <circle r="45" cx="50" cy="50" fill="${obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_ACENTUACION","gray")}" />
        <g >
        <g transform="rotate(0 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-1.2061403508771928s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(30 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-1.0964912280701753s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(60 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.9868421052631577s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(90 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.8771929824561403s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(120 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.7675438596491228s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(150 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.6578947368421052s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(180 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.5482456140350876s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(210 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.43859649122807015s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(240 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.3289473684210526s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(270 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.21929824561403508s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(300 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="-0.10964912280701754s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g transform="rotate(330 50 50)">
        <rect fill="${color_interior}" height="13" width="5" ry="3.12" rx="2.5" y="23.5" x="47.5">
        <animate repeatCount="indefinite" begin="0s" dur="1.3157894736842106s" keyTimes="0;1" values="1;0" attributeName="opacity"></animate>
        </rect>
    </g><g></g></g>
    
    </svg>`;
    return svgLoader;
    }
    const iniciarPruebaVida = async ()=>{
        if (faceTec.current) {
            faceTec.current.abrirLiveness();
            setInstrucciones(false);
        }
    }
    const manejadorPruebaVida=(data)=>{
        console.log(data);
        if (data.message ==="Prueba Relizada Correctamente") {
            localStorage.setItem("img_selfie_lv", data.data.auditTrail[0]);
            if (data.OK) {
                history("/curp");
            }
            else{
                setError("Prueba de vida no superada")
                
            }
            
        }
    }
    const permisoCamara = () => {
        let deviceFuncional = false;
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                let device = devices[i];
                if (device.kind === 'videoinput') {
                    console.log("device",device);
                    deviceFuncional = true
                }
            }
            start(deviceFuncional)
        });
    }

    const start = (deviceFuncional) => {
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const constraints = {
            audio: false,
            video: {
                facingMode: "user",
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 960, height: 720 }
                ]
            }
        };
        if (!isMobile) {
            refVideo.current.classList.add("myVideo_rotate");
        }
        //console.log("Reconfiguracion",constraints);
        let localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch(
                (error) => {
                    if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
                        //required track is missing 
                        //evento('Captura ID', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true, props);
                    } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
                        //webcam or mic are already in use 
                        //evento('Captura ID', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true, props);
                    } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
                        //constraints can not be satisfied by avb. devices 
                        //evento('Captura ID', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true, props);
                    } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
                        //permission denied in browser 
                        //evento('Captura ID', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true, props);
                    } else if (error.name === "TypeError" || error.name === "TypeError") {
                        //empty constraints object 
                        //evento('Captura ID', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true, props);
                    } else {
                        //other errors 
                        //evento('Captura ID', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true, props);
                    }
                    setErrorVideo(true);
                }
            );

        setLocalStream(localStream);

        let width = 320;
        let height = 0;
        localStream.then(function (mediaStream) {
            refVideo.current.srcObject = mediaStream;
            refVideo.current.onloadedmetadata = function (e) {
                refVideo.current.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    if (window.MediaRecorder) {
                        //console.log("disponibles");
                        console.log('Soporta MediaRecorder');
                        initRecord();
                    } else {
                        //console.log('No soporta MediaRecorder');
                        setErrorVideo(true);
                    }
                } else {
                    //console.log("No disponibles");    
                    setErrorVideo(true);
                }
            };
            refVideo.current.addEventListener('canplay', function (ev) {
                height = refVideo.current.videoHeight / (refVideo.current.videoWidth / width);
                refCanvas.current.setAttribute('width', refVideo.current.videoWidth * 1.5);
                refCanvas.current.setAttribute('height', refVideo.current.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            setErrorVideo(true);
        })

    }

    const stopProcess = () => {
        //evento('Captura Video Token', 'Click', { description: 'CANCELAR' }, true);
        console.log("Cancelando");
        setLoading(true)
        if(refVideo.current){
            refVideo.current.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }

        setTimeout(() => {
            setLoading(false)
            history('/')
        }, 500);
    }

    const initRecord = () => {
        setTimeout(() => {
            console.log("initrecord");
            takePhoto()
        }, 1000);

    }

    const takePhoto = () => {

        const localVideo = document.getElementById('video_wrt_front');
        if (!localVideo) return;
        localVideo.autoplay = true;
        localVideo.muted = true;

        console.log("inicio la grabacion");
        let intentos = 0;
        let blobvideo = null
        let titulo = document.getElementById("head_shop");
        localVideo.classList.remove("blur_video")

        setTimeout(() => {
            setLoading(false)
            let interval = setInterval(() => {
                intentos++;
                if (intentos === 1) {
                    //titulo.innerHTML = '<p class="animate__animated animate__slideInDown">Ubica tu rostro de <b>frente</b> para empezar.</p>';
                    setACuadro(true)
                }
                if (intentos === 3){
                    console.log("tomando fotos");
                    let contextCanvas = refCanvas.current.getContext('2d');
                    contextCanvas.drawImage(refVideo.current, 0, 0, refVideo.current.videoWidth * 1.5, refVideo.current.videoHeight * 1.5);
                    refCanvas.current.toBlob((blob) => {
                        console.log("blob",blob);
                        setBlob(blob)
                    }, 'image/jpeg', 0.9);
                }
                if (intentos === 4) {
                    localVideo.classList.add("blur_video")
                    setACuadro(false)
                    titulo.className = "animate__animated animate__fadeIn txt_videotoken";
                    titulo.innerHTML = '<p class="animate__animated animate__slideInDown">Procesando, gracias por tu <b>paciencia</b></p>';
                }
                if(intentos === 5){
                    setPhotoTaken(true)
                }
                if (intentos === 6) {
                    titulo.className = "animate__animated txt_videotoken";
                    titulo.innerHTML = '<p class="animate__animated animate__slideInUp"></p>';
                    clearInterval(interval)
                }
            }, 1000);
        }, 1000);

    }

    const sendImage = (curp) => {
        setLoadingList(true)
        console.log("se envio el video");
        tobase();
        validarRegistro(curp, blob)
            .then((response) => {
                if (response.status === 200) {
                    console.log("response",response);
                    localStorage.setItem("trx",response.data.transaction_code)
                    if(refVideo.current){
                        refVideo.current.srcObject.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                    revisarResultado(response.data.transaction_code)
                }
            })
            .catch((error) => {
                //evento('Captura Video Token', tipo, informacion, false);
                setError(true);
                console.log("Error:", error.response);
                setLoadingList(false);
                if(error.response.data)
                    setErrorText(error.response.data.error.description);
                else
                    setErrorText("Por favor vuelve a intentar");
                let video = document.getElementById('video_wrt');
                if (video) {
                    video.classList.remove("blur_video");
                } else {
                    return;
                }
            })
            .finally(() => {
                document.getElementById("btnValidar").disabled = false;
                document.getElementById("btnValidar").innerHTML="VALIDAR";
            })
    }

    const revisarResultado = async (trxB) => {
        //console.log("entrando a revisar status");
        let intervalFinalizar = setInterval(() => {
            obtenerStatus(trxB).then(async (response) => {
                console.log("Esperando...", intervalFinalizar);
                if (response.status === 200) {
                    if(response.data.score.result_text){
                        clearInterval(intervalFinalizar)
                        setTimeout(() => {
                            //setLoadingList(false);
                            history('/finalizado')
                        }, 100);
                    }else if(response.data.errors[0].description_error) {
                        clearInterval(intervalFinalizar)
                        setTimeout(() => {
                            //setLoadingList(false);
                            history('/finalizado')
                        }, 100);
                    }
                }
            }).catch((e) => {
                console.log("algun error " + e);
            })
        }, 1000);
    }

    const tobase = () => {
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            var base64data = reader.result;                
            localStorage.setItem("img", base64data)
        }
    }

    //  Función para validar código
    const enviarInfo = (e) => {
        console.log("enviarInfo");
        let curp = (document.getElementById("curp").value).toUpperCase()
        if(curpValida(curp)){
            if(Gps !== ""){
                localStorage.setItem("curp",curp)
                document.getElementById("btnValidar").innerHTML="VALIDANDO..."
                document.getElementById("btnValidar").disabled = true;
                sendImage(curp.trim())
            }else{
                setErrorUbicacion(true);
            }
        }else{
            setErrorCurp(true)
        }
    }

    const geoLocalizacion = () => {
        let output = document.getElementById("out");

        if (!navigator.geolocation) {
            output.innerHTML = "<p>Su navegador no soporta la geolocalización</p>";
            return;
        }

        function success(position) {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
            console.log("[" + latitude + "," + longitude + "]");
            output.innerHTML = " ";
            setGps("[" + latitude + "," + longitude + "]");
            setErrorUbicacion(false);
            setLoading(false)
        };

        function error() {
            setErrorUbicacion(true);
            setLoading(false)
            //output.innerHTML = "No se puede encontrar su ubicación";
        };

        
        //output.innerHTML = "<p>Localizando…</p>";
        setLoading(true)
        navigator.geolocation.getCurrentPosition(success, error);

        let element = document.getElementById("check_switch");
        element.setAttribute("disabled", "disabled");
    }

    const curpValida = (curpInput) => {
        let curp = curpInput.toUpperCase()
        let re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
            validado = curp.match(re);
        
        if (!validado)  //Coincide con el formato general?
            return false;
        
        //Validar que coincida el dígito verificador
        
        function digitoVerificador(curp17) {
            //Fuente https://consultas.curp.gob.mx/CurpSP/
            let diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
                lngSuma      = 0.0,
                lngDigito    = 0.0;
            for(let i=0; i<17; i++)
                lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
            lngDigito = 10 - lngSuma % 10;
            if (lngDigito == 10) return 0;
            return lngDigito;
        }

        if (validado[2] != digitoVerificador(validado[1])) 
            return false;
        
        return true; //Validado
    }

    const reintentar = () =>{
        if(facetecCargado){
            setInstrucciones(true);
            setPaso(0);
            setTextoBoton("Siguiente");
            setImagenGuia(1)
        }
        else{
            setMostrarLaoder(true);
            iniciarFaceTec();
        }
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase();

    return (
        <div>
            {
                (instrucciones) ? 
                        <div className="main_gradient">
                            <Header />
                            <div className="main_text_container text-left custom_padding animate__animated animate__fadeIn animate__delay-1s">
                                <div className='conteiner_instruccions-alineacion'>
                                    <div className='container_instruccions-alinacion-texto'>
                                    <p style={{fontSize:"48px", color:obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_ACENTUACION","")}} >Por favor,</p>
                                    <p style={{fontSize:"32px", color:"#2D2D2D"}}>Mantén tu rostro </p>
                                    <p style={{fontSize:"24px"}}><label style={{fontSize:"48px"}}>visible,</label> sin más</p>
                                    <p style={{fontSize:"24px", color:"#565656", fontWeight:"400"}}> <b>personas</b> en la toma, <b>no</b> </p>
                                    <p style={{fontSize:"18.4px", fontWeight:"400"}}> utilices <b>gorra</b> y <b>evita</b> colocarte  </p>
                                    <p style={{fontSize:"18.4px", fontWeight:"400"}}> <b>frente</b> a <b>fuentes</b> de luz. </p>
                                    </div>
                                    <br></br>
                                    
                                   <img hidden={imagenGuia !=1}  src={"images/selfie_error_g1.svg"}  alt="" />
                                   <img hidden={imagenGuia != 2}  src={"images/selfie_error_g2.svg"}  alt="" />
                                    
                                  
                                  
                                </div>
                            </div>
                            <div className="action_buttons noscroll_screen  animate__animated animate__fadeIn animate__delay-1s">
                                <button disabled={!facetecCargado} type="button" onClick={e => {
                                    avanzar()
                                    }} className="btn btn-raised btn-primary forcewidth25 main_bg_color animate__animated animate__fadeIn animate__delay-1s boton_validar_curp-color"
                                    >{textoBoton}</button>
                            </div>
                        </div>

                        :
                        <div className='dco-logo_liveness-posocion'>
                            <img alt='logo' src={obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"LOGO_OTORGANTE","")}></img>
                        </div>
                       
            }
            {error?
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Atención</h5>
                                    <button  className="close" data-dismiss="modal" aria-label="Close" onClick={e => {setError(false); reintentar()}} >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>{error}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={e => {setError(false); reintentar()}} className="btn btn-raised btn-primary main_bg_color">ENTENDIDO</button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                
            {
            mostrarLaoder?
            <Loader/>:null
            
            }
            <div>
           
            </div>
        </div>
    )
}

export default CapturePhoto
