import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const Ayuda = ({ hide }) => {
	const location = useLocation();
	const [dataOtorgante, setDataOtorgante] = useState([])
	const [uuid, setUuid] = useState("")
	const [pass, setPass] = useState(false)
	const { ruta } = useParams();

	useEffect(() => {
		let dataOtorganteLS = localStorage.getItem("dataOtorgante")
		if (dataOtorganteLS != null) {
			setDataOtorgante(JSON.parse(dataOtorganteLS))
			configStyle(JSON.parse(dataOtorganteLS))
		}
		let uuidUser = localStorage.getItem("uuidUser")
		if (uuidUser != null) {
			setUuid(uuidUser)
		}
		if (location.state) {
			if (location.state.passport) {
				setPass(true)
			} else {
				setPass(false)
			}
		}

	}, []);

	useEffect(() => {
		configStyle(dataOtorgante)
	}, [dataOtorgante])

	return (
		<div className="main_gradient">
			<div id="head_container">
				<a className="help_back" onClick={e => hide()}><div className="btn_square_back" /></a>		
				{/* <a className="help_back" onClick={e => hide()}><img src={process.env.PUBLIC_URL + '/images/btn_square_back.svg'} alt="" /></a>		 */}
				<img className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
			</div>
			<div className="main_text_container">
				<h1 className="animate__animated">Estamos para ti</h1>
				<p className="animate__animated">Si <b>necesitas ayuda</b> durante el proceso o tienes <b>algún problema</b> con el uso de la plataforma.</p>
				<br />
				<div className="help_container">
					<p className="help_mail"><b>Escríbenos a:</b>
						<br />
						<span className="d-flex align-items-center">
							<i className="arroba"></i><a href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
						</span>
					</p>
					<p className="help_phone"><b>Llámanos al:</b>
						<br />
						<span className="d-flex align-items-center">
							<i className="phone"></i><a href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
						</span>
					</p>
					<p className="help_whats"><b>Hablemos en:</b>
						<br />
						<span className="d-flex align-items-center">
							<i className="whatsapp"></i><a rel="external" href={"https://wa.me/" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL') + "?text=Tuve%20problemas%20en%20el%20proceso%20mi%20ID%20de%20transacci%C3%B3n%20es%20%20" + uuid.split("-")[0]}>WhatsApp</a>
						</span>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Ayuda