import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import { obtenerValorConfig, configStyle } from '../services/configStyle'
import { validarRegistro , obtenerStatus} from '../services/api';
import Loader from "../components/loader";
const host = process.env.REACT_APP_SERVER //|| "devdicio"

const CURP = () => {
    const history = useNavigate();
    const [Gps, setGps] = useState(null);
    const [curp, setCurp] = useState("");
    const [validando, setValidando] = useState(false)
    const [mostrarLoader, setMostrarLoader] = useState(false);
    const validar = async ()=>{
        setValidando(true);
        setMostrarLoader(true)
        //history("/finalizado");
        sendImage(curp, localStorage.getItem("img_selfie_lv") )
    }
    const sendImage = async (curp, imageB64) => {
        console.log("se envio el video");
        let url = "data:image/jpg;base64, " + imageB64;

        let res = await fetch(url);
        let blob = await res.blob()
       
        validarRegistro(curp, blob)
            .then((response) => {
                if (response.status === 200) {
                    console.log("response",response);
                    localStorage.setItem("trx",response.data.transaction_code)
                    
                    revisarResultado(response.data.transaction_code)
                }
            })
            .catch((error) => {
                history('/finalizado')
                setValidando(false);
                setMostrarLoader(false);
                //evento('Captura Video Token', tipo, informacion, false);
                /*setError(true);
                console.log("Error:", error.response);
                setLoadingList(false);
                if(error.response.data)
                    setErrorText(error.response.data.error.description);
                else
                    setErrorText("Por favor vuelve a intentar");*/
                
            });
            
    }

    const revisarResultado = async (trxB) => {
        //console.log("entrando a revisar status");
        let intervalFinalizar = setInterval(() => {
            obtenerStatus(trxB).then(async (response) => {
                console.log("Esperando...", intervalFinalizar);
                console.log(response);
                if (response.status === 200) {
                    if(response.data.score.result_text && response.data.score.result_text =="green"){
                        clearInterval(intervalFinalizar)
                        setTimeout(() => {
                            localStorage.setItem("result", "1")
                            //setLoadingList(false);
                            history('/finalizado')
                            console.log("Correcto")
                            setValidando(false);
                            setMostrarLoader(false);
                        }, 100);
                    }
                    else if(response.data.score.result_text) {
                        clearInterval(intervalFinalizar)
                        setTimeout(() => {
                            console.log("Incorrecto")
                            localStorage.setItem("result", "0")
                            //setLoadingList(false);
                            history('/finalizado')
                            setValidando(false);
                            setMostrarLoader(false);
                        }, 100);
                    }
                    else if(response.data.errors[0].description_error) {
                        clearInterval(intervalFinalizar)
                        setTimeout(() => {
                            console.log("Incorrecto")
                            localStorage.setItem("result", "0")
                            //setLoadingList(false);
                            history('/finalizado')
                            setValidando(false);
                            setMostrarLoader(false);
                        }, 100);
                    }
                }
            }).catch((e) => {
                console.log("algun error " + e);
            })
        }, 1000);
    }

    const geoLocalizacion = () => {
        setMostrarLoader(true);
        let output = document.getElementById("out");

        if (!navigator.geolocation) {
            output.innerHTML = "<p>Su navegador no soporta la geolocalización</p>";
            return;
        }

        let success=(position) => {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
            console.log("[" + latitude + "," + longitude + "]");
            output.innerHTML = " ";
            setGps("[" + latitude + "," + longitude + "]");
            setMostrarLoader(false);
        };

        let error=()=> {
            setMostrarLoader(false);
            //output.innerHTML = "No se puede encontrar su ubicación";
        };

        
        //output.innerHTML = "<p>Localizando…</p>";
        
        navigator.geolocation.getCurrentPosition(success, error);

        let element = document.getElementById("check_switch");
        element.setAttribute("disabled", "disabled");
    }
    const menajadorCURP = (e) =>{
        let curp =e.target.value;
        setCurp(curp.toUpperCase())
    }
    const curpValida = (curpInput) => {
        let curp = curpInput.toUpperCase()
        let re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
            validado = curp.match(re);
        
        if (!validado)  //Coincide con el formato general?
            return false;
        
        //Validar que coincida el dígito verificador
        
        function digitoVerificador(curp17) {
            //Fuente https://consultas.curp.gob.mx/CurpSP/
            let diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
                lngSuma      = 0.0,
                lngDigito    = 0.0;
            for(let i=0; i<17; i++)
                lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
            lngDigito = 10 - lngSuma % 10;
            if (lngDigito == 10) return 0;
            return lngDigito;
        }

        if (validado[2] != digitoVerificador(validado[1])) 
            return false;
        
        return true; //Validado
    }
    return (
        <div>
         
                <div className="main_gradient ">
                <Header />
                <div className="main_text_container text-left custom_padding animate__animated animate__fadeIn animate__delay-1s">
                                <div className='conteiner_instruccions-alineacion'>
                                    <div className='container_instruccions-alinacion-texto'>
                                    <p style={{fontSize:"48px", color: obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_ACENTUACION","")}} >
                                        Muy bien,</p>
                                    <p style={{fontSize:"32px", color:"#2D2D2D"}}>captura tu CURP </p>
                                   
                                    </div>
                                    <br></br>
                                  
                                </div>
                                <div className="main_text_container  animate__animated animate__slideInUp">
                                        <div >
                                            <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                                            <input type="text" className="form-control none_border" id="curp" name="curp" maxLength="18" onChange={menajadorCURP}
                                                style={{textTransform:"uppercase",backgroundImage: "linear-gradient(to top, #CACED3 2px, rgba(0, 150, 136, 0) 2px), linear-gradient(to top, rgba(0, 0, 0, 0.26) 1px, rgba(0, 0, 0, 0) 1px)"}}
                                            placeholder="Ej: SOAL821001HDFTYT765"
                                            />
                                        </div>
                                        <br></br>
                                        <table className="ubicacionGPS">
                                            <tbody>
                                                <tr>
                                                    <th><div className="location_icon"></div></th>
                                                    <th> Validar ubicación<br></br><small>Activa tu GPS</small></th>
                                                    <th>
                                                        <div className="checkboxswitch" style={{position:"relative",left:"15px"}}>
                                                            <label htmlFor="check_switch" className="switchDir" >
                                                                <input id="check_switch" type="checkbox" onChange={geoLocalizacion} />
                                                                <span className="sliderDir round"></span>
                                                            </label>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table> 
                                        <div id="out"></div>
                                        <br></br>
                                        <br></br>
                                    </div>
                </div>

                <div className="action_buttons noscroll_screen animate__animated animate__fadeIn animate__delay-1s">
                        <button onClick={validar} disabled={!(curpValida(curp) && Gps && !validando)} 
                        className="btn btn-raised btn-primary forcewidth30 main_bg_color boton_validar_curp-color">
                        Validar
                        <img style={{marginLeft:"20px"}} src="/images/correct_icon.svg"/>
                        </button>
                </div>

                </div>
                {mostrarLoader?
                <Loader/>:null
                }
        </div>

    )
}

export default CURP
