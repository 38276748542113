import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import { obtenerParametrosOtorgante } from '../services/api';
import { obtenerValorConfig, configStyle } from '../services/configStyle'
import LoaderDemo from "../components/loaderDemo";
const host = process.env.REACT_APP_SERVER || "qadicio"

const Inicio = () => {
    const history = useNavigate();

    const [hayCookies] = useState(navigator.cookieEnabled);
    const [apikey, setApikey] = useState("")
    const [show, setShow] = useState(false)
    const [splash, setSplash] = useState(true)
    const [colorAcentuacion, setColorAcentuacion] = useState("gray")
    
    useEffect(() => {
        localStorage.clear();
        console.log("limpie localStorage");
        setTimeout(() => {
            
            let uuidUser = localStorage.getItem("uuidUser");
            if (uuidUser === null) {
                let newUuid = uuidv4();
                localStorage.setItem("uuidUser", newUuid);
                localStorage.setItem("uuidTrx", newUuid);
                console.log("newUuid",newUuid);
            }
            let idOtorgante= "17ee713c-9311-4b4e-a9c9-91d3ec7c61ca" // nodo 2 baz
            if((window.location.href).includes("afirme") )
            { 
                //nodo 3
                idOtorgante = "f26707d9-8646-47c4-b9af-9acd37d4769e"
                localStorage.setItem('hostNode', `https://nodegcp.${host}.net:8443`)
            }
            else if ((window.location.href).includes("bancoppel"))
            { 
                //nodo 1
                idOtorgante = "9a942652-64d8-4882-ac83-baffe5c931d7"       
                localStorage.setItem('hostNode', `https://nodehw.${host}.net:8443`)
                //localStorage.setItem('hostNode', `https://node1.${host}.net:8443`)
            }else if ((window.location.href).includes("banorte") )
            { 
                //nodo 3
                idOtorgante = "f26707d9-8646-47c4-b9af-9acd37d4769e"
                localStorage.setItem('hostNode', `https://nodegcp.${host}.net:8443`)
            }
            else
            { 
                //nodo 2 baz
                localStorage.setItem('hostNode', `https://nodeaws.${host}.net:8443`)
                
            }
            console.log("hostNode", localStorage.getItem('hostNode'));
    
            if(hayCookies){
                console.log("obtenerParametrosOtorgante");
                obtenerParametrosOtorgante(idOtorgante).then((data) => {
                    if (data.status === 200) {
                        if (hayCookies) {
                            let dataOtorgante = data.data.payload
                            localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
                            let api = obtenerValorConfig(dataOtorgante, 'APIKEY', "")
                            setColorAcentuacion(obtenerValorConfig(dataOtorgante,"COLOR_ACENTUACION",""));
                            localStorage.setItem('apikeyOtorgante',api)
                            setApikey(api)
                            configStyle(dataOtorgante)
                            let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
                            document.getElementById("imagenLogo").src = logo;
                            let ID_OTORGANTE = (obtenerValorConfig(dataOtorgante, 'ID_OTORGANTE', ""));
                            if (ID_OTORGANTE !== "") {
                                localStorage.setItem("uuidOtorgante", ID_OTORGANTE)
                            } else {
                                //console.log("no hay uuid otrogante");
                            }
                            
                        }
                        
                    }
                }).catch((error) => {
                    console.log('Error', error);
                }).finally(()=>{
                    setTimeout(()=>{setSplash(false)},500)
                })
                
            }
        }, 3500);
        
    }, []);

    const reload = () => {
        window.location.reload();
    }

    const aceptarTerminos = check => {
        if (check) {
            setShow(true)
        }else{
            setShow(false)
        }
    };

    const goVideotoken = check => {
        setTimeout(() => {
            history("/capturePhoto" )
        }, 300);
    };

    return (
        <div>
            {
                (splash) ? 
                <div>
                    <div className="splash">
                        <div className="">
                            <img src="images/logo-aliance.svg"></img>
                        </div>
                    </div>
                    <div className="contenedor-loader-liveness-dco">
                    <LoaderDemo/>
                    </div>
                </div>
                :
                <div className="main_gradient">
                <Header />
                <div className="main_text_container text-left custom_padding animate__animated animate__fadeIn animate__delay-1s">
                                <div className='conteiner_instruccions-alineacion'>
                                    <div className='container_instruccions-alinacion-texto'>
                                    <p style={{fontSize:"48px", color: colorAcentuacion}} ><b>Hola,</b></p>
                                    <p style={{fontSize:"32px", color:"#2D2D2D"}}>vamos a validar </p>
                                    <p style={{fontSize:"24px", color:"#78787B"}}><label style={{fontSize:"48px"}}>tu identidad</label> </p>
                                  
                                    </div>
                                    <br></br>
                                   <img src="images/Face_ID_logo.svg"  alt="" />
                                  
                                  
                                </div>
                </div>

                <div className="action_buttons noscroll_screen animate__animated animate__fadeIn animate__delay-1s">
                        <button onClick={e => goVideotoken()}  style={{textTransform:"none"}} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                        Vamos, ya tengo mi CURP a mano
                        <img style={{marginLeft:"15px"}} src="/images/correct_icon.svg"/>
                        </button>
                </div>

                {
                    (!hayCookies) ? (<div className="err_finale  animate__animated animate__slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu navegador</h5>
                            <p>Para acceder a este sitio es necesario habilitar el uso de Cookies
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                            </div>
                        </div>
                    </div>) : ("")}
            </div>
            }


        </div>
    )
}

export default Inicio
