import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esEditable, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento, sendZip, statusError } from '../../services/data';
import Loader from '../../components/loader';
import moment from 'moment';

let FormularioPasaporte = (props) => {
    const history = useNavigate();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombreCompleto: '',
        fechaNacimiento: '',
        vigenciaPasaporte: '',
        curp: ''
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esPermanente, setEsPermanente] = useState(false);
    const [esMigratoria, setEsMigratoria] = useState(false);

    const [fechaNacimiento, setfechaNacimiento] = useState("");
    const [fechaVigencia, setfechaVigencia] = useState("");
    const [esVigente, setEsVigente] = useState(true);
    const [sendForm, setSendForm] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            if (ocrFront.nombreCompleto)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrFront.nombreCompleto }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
                setfechaNacimiento(ocrFront.fechaNacimiento)
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.vigencia) {
                if (ocrFront.identificacionPasaporte) {
                    if (ocrFront.vigencia.includes(' '))
                        vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                    else
                        vigenciaC = ocrFront.vigencia
                    if (ocrFront.identificacionPasaporte) {
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia;
                    }
                } else if (ocrFront.identificacionMigratoria) {
                        vigenciaC = ocrFront.vigencia;
                }
                setfechaVigencia(ocrFront.vigencia);
            }
            if (ocrFront.documentoPermanente) {
                setEsPermanente(true);
                delete inputsValue.vigenciaPasaporte;
                delete inputsErrors.vigenciaPasaporte;
            } else {
                setInputsValue(inputsValue => ({ ...inputsValue, ["vigenciaPasaporte"]: vigenciaC || '' }));
            }
        }

        const dataOCRBackLS = localStorage.getItem("ocrBack");
        if (dataOCRBackLS) {
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';
            if (ocrBack.ineVigencia && !ocrFront.documentoPermanente) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                }
            }
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (event.target.name === "fechaNacimiento") {
            setfechaNacimiento(event.target.value)
        }
        if (event.target.name === "vigenciaPasaporte") {
            setfechaVigencia(event.target.value)
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(validacion.errores);
        } else {
            setInputsErrors(validacion.errores);
        }

    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);
        let fechaNacimientoValue = document.querySelector('#fechaNacimiento').value;
        let fechaVigencia = document.querySelector('#vigenciaPasaporte');
        inputsValue.fechaNacimiento = fechaNacimientoValue;
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombreCompleto", value: inputsValue.nombreCompleto });
            infoFormulario.push({ description: "nombreCompleto", value: inputsValue.nombreCompleto })
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp })
            if (fechaVigencia) {
                inputsValue.vigenciaPasaporte = fechaVigencia.value;
                infoFormulario.push({ description: "vigencia", value: fechaVigencia.value })
                agregarInfo(dataUser, { description: "vigencia", value: fechaVigencia.value });
                let fechaActual = fechaVigencia.value;
                let dateParts = fechaActual.split("/");
                let fechaV = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                if (fechaV >= Date.now()) {
                    setTimeout(() => {
                        sendData(infoFormulario);
                        if (hayExcepcion()) {
                            history('/clausula');
                        } else {
                            history('/video_token')
                        }
                    }, 300);
                } else {
                    setEsVigente(false)
                }
            } else {
                infoFormulario.push({ description: "vigencia", value: 'PERMANENTE' })
                agregarInfo(dataUser, { description: "vigencia", value: 'PERMANENTE' });
                setTimeout(() => {
                    sendData(infoFormulario);
                    if (hayExcepcion()) {
                        history('/clausula');
                    } else {
                        history('/video_token')
                    }
                }, 300);
            }

        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const statusSE = () => {
        evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)
    }

    const terminarFlujo = async (event) => {
        if (event) event.preventDefault();
        let data = "Cancelado";
        setLoading(true);
        const responseZip = await sendZip('TRUNCOS', 'trunco');
        statusError("datos_personales_ocr", data, "cancelado");
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true);
        if (window.opener) {
            setTimeout(() => {
                window.close();
            }, 300);
        } else {
            setTimeout(() => {
                history("/" + apiKey);
                setLoading(false);
            }, 300);
        }
    }

    return (
        <>
            <form id="FormularioPasaporte">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombreCompleto === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control" id="fechaNacimiento" name="fechaNacimiento" defaultValue={inputsValue.fechaNacimiento} value={fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    {!esPermanente &&
                        <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigenciaPasaporte !== "") ? "is-focused" : ""), inputsErrors.vigenciaPasaporte && 'error'].join(" ")}>
                            <label htmlFor="vigenciaPasaporte" className="bmd-label-floating">Vencimiento identificación <small>(DD/MM/AAAA)</small>:</label>
                            <NumberFormat format="##/##/####" className="form-control none_border" id="vigenciaPasaporte" name="vigenciaPasaporte" defaultValue={inputsValue.vigenciaPasaporte} value={fechaVigencia} onChange={handleChange} onFocus={e => {
                                setFocusVigencia(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (inputsValue.vigenciaPasaporte === "") {
                                    setFocusVigencia(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.vigenciaPasaporte && (
                                <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigenciaPasaporte}</span>
                            )}
                        </div>}
                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP</label>
                        {
                            (esMigratoria) ?
                                <input type="text" className="form-control" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.curp === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} maxLength="19" />
                                :
                                <input type="text" className="form-control" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.curp === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} />
                        }
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>
                </div>
                {(!esVigente) ? (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button className="btn btn-secondary forcewidth100" onClick={event => terminarFlujo(event)}>TERMINAR</button>
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                            </div>
                        </div>
                    </div>) : ("")}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={statusSE}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
            {loading && <Loader />}
        </>
    )
}

export default FormularioPasaporte