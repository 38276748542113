import { json } from "react-router-dom";

export var Config = (function() {
 // -------------------------------------
  // REQUIRED
  // Available at https://dev.facetec.com/account
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  //var DeviceKeyIdentifier = "daLIAcMCAJ8vpV6AMrkCtnxqtuFQm0K8";

    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    //var BaseURL = "https://api.facetec.com/api/v3.1/biometrics";

    // -------------------------------------
    // REQUIRED
    // The FaceScan Encryption Key you define for your application.
    // Please see https://dev.facetec.com/facemap-encryption-keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    /*var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
        "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
        "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
        "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
        "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
        "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
        "8QIDAQAB\n" +
        "-----END PUBLIC KEY-----"    */
        ;
  // -------------------------------------
    var DeviceKeyIdentifier = "dvJgFXp0HUO7g5mgUK2n5zPa6C70akAT";

    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var BaseURL = "https://biofacial.cdc-dev.link";

    // -------------------------------------
    // REQUIRED
    // The FaceScan Encryption Key you define for your application.
    // Please see https://dev.facetec.com/facemap-encryption-keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0LnYH+CZ7AKMHQ+x/eIK\nXGuKb7AzCnUzWs9w4K1J7KlgRhDD9w0vi+5HuZ+KOa1ebKydd0ppTGFOAs3R4jh7\nosX3aGo6G2v20fYkZN9dtpItEf6qmw5oaZLBdNO2lAXTZcbpp/01sIQLD3tt4U48\nT/T6NyuqtURy8vtUuHVurrPbGdLm+9WvzL3yKWZIdCamdo9pdDiB/2XAdvcwIMZS\nWFXnUuNMnlmnXqVsyUyztfF4B09AVl0QxCVO3rT42cBN9Qdxeb6bO1cazMjuEz3Q\nbYhtUh3ndP2+hHIk59GkN2lsPvf/Ko9tAryzOjhGSwoF6H6bd2hORhDvaMhW/N7l\nUwIDAQAB\n-----END PUBLIC KEY-----"    
        ;
  // Convenience method to initialize the FaceTec SDK.
  // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
  function initializeFromAutogeneratedConfig(FaceTecSDK, callback) {
    FaceTecSDK.initializeInDevelopmentMode(DeviceKeyIdentifier, PublicFaceScanEncryptionKey,
      function(initializedSuccessfully) {
        callback(initializedSuccessfully);
      });
  };

  function initializeFromAutogeneratedConfigProd(ProductionKey,DeviceKeyIdentifierProd,PublicFaceScanEncryptionKeyProd, BaseURLProd,
    FaceTecSDK, callback) {
      BaseURL= BaseURLProd;
      DeviceKeyIdentifier = DeviceKeyIdentifierProd;
      PublicFaceScanEncryptionKey = PublicFaceScanEncryptionKeyProd;
    FaceTecSDK.initializeInProductionMode(ProductionKey,DeviceKeyIdentifierProd, PublicFaceScanEncryptionKeyProd,
      (initializedSuccessfully) =>{
        callback(initializedSuccessfully);
      });
  };

  // This app can modify the customization to demonstrate different look/feel preferences
  // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
  function retrieveConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    currentCustomization = defaultCustomization;
    return defaultCustomization;
  }

  ;

  function retrieveLowLightConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    currentLowLightCustomization = defaultCustomization;
    return defaultCustomization;
  }

  ;

  function retrieveDynamicDimmingConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    currentDynamicDimmingCustomization = defaultCustomization;
    return defaultCustomization;
  }

  ;

  var currentCustomization;
  var currentLowLightCustomization;
  var currentDynamicDimmingCustomization;

  // -------------------------------------
  // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
  // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's "Toggle Colors & Themes" menu,
  // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
  // Configuration Wizard.
  var wasSDKConfiguredWithConfigWizard = false;

  return {
    wasSDKConfiguredWithConfigWizard,
    DeviceKeyIdentifier,
    BaseURL,
    PublicFaceScanEncryptionKey,
    initializeFromAutogeneratedConfig,
    initializeFromAutogeneratedConfigProd,
    currentCustomization,
    currentLowLightCustomization,
    currentDynamicDimmingCustomization,
    retrieveConfigurationWizardCustomization,
    retrieveLowLightConfigurationWizardCustomization,
    retrieveDynamicDimmingConfigurationWizardCustomization
  };
})();
