import { Config } from "./Config.js";
import { LivenessCheckProcessor } from "./FaceTecAngularSampleApp/src/processors/LivenessCheckProcessor.ts";
import {FaceTecStrings} from "../../core-sdk/FaceTecStrings.es.ts"
import {FaceTecSDK} from "../../core-sdk/FaceTecSDK.js/FaceTecSDK.js"
import { createElement } from "react";


export class controladorFaceTec {
  #latestProcessor;
  #latestSessionResult;
  #latestIDScanResult;
  #funcionSalidaDatosFinales;
  #funcionSalidaErrores;
  #funcionResposeSalida;
  #svgLoader;
  #colorPrimario;
  #colorTexto;
  constructor(funcionSalidaDatosFinales, funcionErrores, funcionresponse,
    ProductionKey, DeviceKeyIdentifier, PublicFaceScanEncryptionKey, BaseURL , 
    svgLoader, colorPrimario, colorTexto
  ){
    this.iniciar(ProductionKey, DeviceKeyIdentifier, PublicFaceScanEncryptionKey, BaseURL, svgLoader);
    this.#funcionSalidaDatosFinales = funcionSalidaDatosFinales;
    this.#funcionSalidaErrores = funcionErrores;
    this.#funcionResposeSalida = funcionresponse;
    this.#svgLoader=svgLoader;
    this.#colorPrimario=colorPrimario;
    this.#colorTexto = colorTexto;
  }
  iniciar(ProductionKey, DeviceKeyIdentifier, PublicFaceScanEncryptionKey, 
    BaseURL , svgLoader
  
  ){
    try {
       // Set a the directory path for other FaceTec Browser SDK Resources.
     FaceTecSDK.setResourceDirectory("/FaceTecSDK.js/resources");

     // Set the directory path for requi${color_interior} FaceTec Browser SDK images.
     FaceTecSDK.setImagesDirectory("/FaceTec_images/");
     
     /*Config.initializeFromAutogeneratedConfig(
      FaceTecSDK, (initializedSuccessfully) =>{
      if(initializedSuccessfully) {
        FaceTecSDK.configureLocalization(FaceTecStrings);
        let currentCustomization= new FaceTecSDK.FaceTecCustomization();
        currentCustomization = this.ajustarColores(currentCustomization);
        FaceTecSDK.setCustomization(currentCustomization)
        //FaceTecFrameCustomization
        this.#funcionResposeSalida({paso:"iniciado"})

      }
    });*/
     Config.initializeFromAutogeneratedConfigProd(ProductionKey, DeviceKeyIdentifier, PublicFaceScanEncryptionKey, BaseURL,
      FaceTecSDK, (initializedSuccessfully) =>{
      if(initializedSuccessfully) {
        FaceTecSDK.configureLocalization(FaceTecStrings);
        let currentCustomization= new FaceTecSDK.FaceTecCustomization();
        
        currentCustomization = this.ajustarColores(currentCustomization);
        FaceTecSDK.setCustomization(currentCustomization)
        //FaceTecFrameCustomization
        this.#funcionResposeSalida({paso:"iniciado"})

      }
      else{
        console.log(initializedSuccessfully)
        this.#funcionSalidaErrores({error: "Error al inicializar FaceTec",data:initializedSuccessfully});
      }
    });
    } catch (error) {
      this.#funcionSalidaErrores({error: "Error al inicializar FaceTec Conexion",data:error.toString()});
    }
    

  }
  abrirLiveness(){
     // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
    this.getSessionToken((sessionToken) => {
      this.#latestProcessor = new LivenessCheckProcessor(sessionToken , this );
    });
  }
  detenerLiveness(){
    if (this.#latestProcessor) {
      this.#latestProcessor.stop();
    }
  }
  // Get the Session Token from the server
  getSessionToken(sessionTokenCallback)  {
    let sessionTokenErrorHasBeenHandled = false;

    try {
      var XHR = new XMLHttpRequest();
      XHR.open("GET", Config.BaseURL + "/session-token");
      XHR.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
      XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));

      XHR.onreadystatechange =() => {
        if(XHR.readyState === XMLHttpRequest.DONE) {
          var sessionToken = "";

          try {
            // Attempt to get the sessionToken from the response object.
            sessionToken = JSON.parse(XHR.responseText).sessionToken;

            // Something went wrong in parsing the response. Return an error.
            if(typeof sessionToken !== "string") {
              this.onSessionTokenError(XHR.status);
              return;
            }
          }
          catch{
            // Something went wrong in parsing the response. Return an error.
            XHR.abort();
            this.onSessionTokenError(XHR.status);
            return;
          }
          sessionTokenCallback(sessionToken);
        }
      };

      // Wait 3s, if the request is not completed yet, show the session token loading screen
      window.setTimeout(() => {
        if(XHR.readyState !== XMLHttpRequest.DONE) {
          if(sessionTokenErrorHasBeenHandled === false) {
          }
        }
      }, 3000);

      XHR.onerror = () =>{
        XHR.abort();
        this.onSessionTokenError(XHR.status);
      };

      XHR.send();
    }
    catch(e) {
      this.onSessionTokenError(undefined);
    }
  }
  onSessionTokenError(error){
   this.#funcionSalidaErrores({error: "Token Error",data:error});
  }

  onComplete = (sessionResult, idScanResult, latestNetworkResponseStatus) => {
    this.#latestSessionResult = sessionResult;
    this.#latestIDScanResult = idScanResult;
    let salida={message:"Error durante prueba", OK:false, data: sessionResult};
    if(!this.#latestProcessor.isSuccess()) {
      // Check for server offline
      if(this.isNetworkResponseServerIsOffline(latestNetworkResponseStatus) === true) {
        salida={message:"Servidor Caido", OK:false, data: sessionResult};
        this.#funcionSalidaErrores({error: "Servidor Caido",data:sessionResult,  status: FaceTecSDK.FaceTecSessionStatus[sessionResult.status]});
      }
      else if(latestNetworkResponseStatus == 0){
        this.#funcionSalidaErrores({error:"Error ${color_interior}", data: "XHR Error"});
      }
      this.#funcionSalidaErrores({error: "NOT SUCESS",data:sessionResult, status: FaceTecSDK.FaceTecSessionStatus[sessionResult.status]});
    }
    
    else{
      if(sessionResult.isCompletelyDone){
        salida={message:"Prueba Relizada Correctamente", OK:true,data: sessionResult};
      }
      else{
        salida={message:"Prueba Incorrecta, vuelva a intentar.",OK:false, data: sessionResult};
      }
    }
    this.#funcionSalidaDatosFinales(salida);
  };
    // Check for server down status
  isNetworkResponseServerIsOffline(networkResponseStatus) {
      return (networkResponseStatus >= 500);
  }

  ajustarColores(currentCustomization){
    currentCustomization.frameCustomization.borderColor="transparent";

    currentCustomization.overlayCustomization.backgroundColor="transparent";
    currentCustomization.overlayCustomization.brandingImage="assets/img/logo.svg"
    currentCustomization.overlayCustomization.showBrandingImage=false;

    currentCustomization.initialLoadingAnimationCustomization.messageTextColor=this.#colorTexto
    currentCustomization.initialLoadingAnimationCustomization.foregroundColor=this.#colorTexto
    currentCustomization.initialLoadingAnimationCustomization.messageFont='"Muli", sans-serif;'
    

    currentCustomization.ovalCustomization.strokeColor=this.#colorPrimario;
    currentCustomization.ovalCustomization.progressColor1=this.#colorPrimario;
    currentCustomization.ovalCustomization.progressColor2=this.#colorPrimario;

    currentCustomization.guidanceCustomization.readyScreenSubtextTextColor=this.#colorTexto;
    currentCustomization.guidanceCustomization.readyScreenHeaderTextColor=this.#colorTexto;
    currentCustomization.guidanceCustomization.buttonBackgroundNormalColor=this.#colorPrimario;
    currentCustomization.guidanceCustomization.buttonBackgroundHighlightColor=this.#colorPrimario;
    currentCustomization.guidanceCustomization.buttonBackgroundDisabledColor="#E4E2E4";
    currentCustomization.guidanceCustomization.readyScreenHeaderFont='"Muli", sans-serif;';
    currentCustomization.guidanceCustomization.readyScreenSubtextFont='"Muli", sans-serif;';
    currentCustomization.guidanceCustomization.buttonFont='"Muli", sans-serif;';
    currentCustomization.guidanceCustomization.headerFont='"Muli", sans-serif;';
    currentCustomization.guidanceCustomization.subtextFont='"Muli", sans-serif;';


    currentCustomization.feedbackCustomization.backgroundColor=this.#colorPrimario;
    currentCustomization.feedbackCustomization.textFont='"Muli", sans-serif;';

    currentCustomization.initialLoadingAnimationCustomization.foregroundColor=this.#colorPrimario;
    currentCustomization.initialLoadingAnimationCustomization.messageTextColor=this.#colorTexto;
    currentCustomization.initialLoadingAnimationCustomization.messageFont='"Muli", sans-serif;';
    //currentCustomization.initialLoadingAnimationCustomization.customAnimation=this.#svgLoader;

    currentCustomization.resultScreenCustomization.activityIndicatorColor=this.#colorPrimario;
    currentCustomization.resultScreenCustomization.foregroundColor=this.#colorPrimario;
    currentCustomization.resultScreenCustomization.resultAnimationForegroundColor=this.#colorPrimario;
    currentCustomization.resultScreenCustomization.resultAnimationBackgroundColor="white";
    currentCustomization.resultScreenCustomization.uploadProgressFillColor=this.#colorPrimario;
    currentCustomization.resultScreenCustomization.messageFont='"Muli", sans-serif;';
    currentCustomization.resultScreenCustomization.showUploadProgressBar=false;
    

    currentCustomization.guidanceCustomization.foregroundColor = this.#colorPrimario;
    currentCustomization.cancelButtonCustomization.location=0;

    currentCustomization.vocalGuidanceCustomization.mode=2;
    
    currentCustomization.resultScreenCustomization.customActivityIndicatorAnimation = this.#svgLoader;
    currentCustomization.initialLoadingAnimationCustomization.customAnimation=this.#svgLoader
    return currentCustomization;
  }
  limpiarFaceTec(){
    try {
      FaceTecSDK.unload((data)=>{console.log(data)});
    } catch (error) {
      console.log(error);
    }
    
  }
}
