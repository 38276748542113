/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig, obtenerValorUser} from '../services/configStyle';
import Loader from "../components/loader";
import Typist from 'react-typist';
import { enviarImagen, enviarVideoRostro, cancelarRequest, validarRegistro } from '../services/api';
import { isIOS, isMobileSafari, isFirefox, isMobile, isIPad13, withOrientationChange } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { status, statusData, statusReintento, evento, flowIncompleted, sendEventClick, validarOtorganteCDC } from '../services/data'
import Header from "../components/header";
import RecordRTC from 'recordrtc';
import { relativeTimeRounding } from 'moment';

const bucket = process.env.REACT_APP_BUCKET;

const msjError = "Lo sentimos no hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente"
const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "Por favor busca una posición con menos luz directa y centra tu rostro en el área marcada al momento de la captura."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjErrorEmbedings = 'Lo sentimos detectamos inconsistencias en tu captura, por favor reinicia el proceso.'
const msjSpoof = 'Lo sentimos no hemos logrado validar tu captura, evita reflejos de luz, limpia tu cámara, sigue las instrucciones e intenta nuevamente.'

class CameraVideoTokenIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        token: null,
        isRecording: false,
        isComplete: false,
        isStart: true,
        uuid: null,
        errorToken: false,
        dataUser: [],
        dataOtorgante: [],
        codes: [],
        face: {},
        errorMessage: "",
        intents: 0,
        apikey: "",
        hasResponse: false,
        conteo: false,
        codec: "video/webm;codecs=vp8",
        errorF: "",
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        showCancel: false,
        showHelp: false,
        stable: false,
        suportMediaRecorder: true,
        intentos: 0,
        retry: true,
        cancelVideoToken: false,
        uuidTrx: null,
        sendOTP: false,
        sendingOTP: true,
        errorPermiso: false, 
        imgBlob: null
    }

    componentDidMount() {
        if (isFirefox && !isMobile) {
            this.state.codec = "video/webm;codecs=vp9"
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }

        let codesLS = localStorage.getItem("codes")
        if (codesLS !== null) {
            this.setState({ codes: JSON.parse(codesLS) });
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let uuidTrx = localStorage.getItem("uuidTrx");
        if (uuidTrx) {
            this.setState({ uuidTrx: uuidTrx });
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }

        this.state.pathname = window.location.pathname;

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }

        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intents = Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"));
            }
        }

        let codigos = localStorage.getItem("");

        /* this.localStream = window.navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        }); */

        status("captura_video_token_page", "Captura Video Token")

        this.canvas = document.querySelector('canvas');
        this.width = 320;
        this.height = 0;
        this.video = document.querySelector('video');
        this.elemento = document.getElementById("cuadro");
        this.titulo = document.getElementById("head_shop");
        /* let component = this;
        this.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    component.setState({ streamStarted: true, errorVideo: false })
                    if (window.MediaRecorder) {
                        //console.log('Soporta MediaRecorder');
                        let mymeType = null
                        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                            mymeType = 'video/webm; codecs=vp9';
                        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                            mymeType = 'video/webm; codecs=vp8';
                        }
                        let type = isIOS ? 'video/mp4' : mymeType;
                        const optionNormal = {
                            type: 'video',
                            mimeType: type,
                        };

                        component.recorder = new RecordRTC(mediaStream, optionNormal);
                    } else {
                        //console.log('No soporta MediaRecorder');
                        component.setState({ suportMediaRecorder: false });
                    }
                } else {
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            this.setState({ errorVideo: true })
        }) */

        this.setState({ intentos: Number(obtenerValorConfig(JSON.parse(dataOtorganteLS), "INTENTOS_VIDEO_TOKEN", "3")) });
    }

    takepicture = () => {
        evento('Captura Video Token', 'WebRTC', { description: 'OK' }, true);
        let tipo = '';
        let informacion = {};
        setTimeout(() => {
            //console.log("tomando foto");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                this.setState({ sendingOTP: false });
                enviarImagen(that.state.uuid, blob)
                    .then((response) => {
                        if (response.status === 200) {
                            informacion.status = response.status;
                            informacion.payload = response.data.payload;
                            evento('OTP', 'Success', informacion, true);
                            let listCodes = response.data.payload.codes;
                            that.setState({ codes: listCodes });
                            localStorage.setItem("codes", JSON.stringify(listCodes));

                            setTimeout(() => {
                                if (this.state.stable) {
                                    let div = document.getElementById("div-stable");
                                    if (!div) return;
                                    div.className = "animate__animated animate__fadeOut";
                                }
                                this.elemento.className = "animate__animated animate__fadeOut";
                                setTimeout(() => {
                                    this.setState({ stable: false });
                                    this.elemento.className = "animate__animated";
                                    this.titulo.classList.add('animate__slideInDown');
                                    this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                                }, 1000);
                            }, 5000);

                            setTimeout(async () => {

                                let tokenLS = listCodes.pop();
                                that.setState({ token: tokenLS });
                                localStorage.setItem("codes", JSON.stringify(listCodes));
                                const token = tokenLS.code;
                                const connectionClient = new ConnectionClient();
                                let peerConnection1 = null;

                                const localVideo = document.getElementById('video_wrt_environment');
                                if (!localVideo) return;
                                localVideo.autoplay = true;
                                localVideo.muted = true;

                                async function beforeAnswer(peerConnection) {
                                    that.localStream.then(function (mediaStream) {
                                        mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                                    })
                                    peerConnection1 = peerConnection;
                                    let dataChannel = null;
                                    let interval = null;
                                    let timeoutt = null;
                                    async function onMessage({ data }) {
                                        const responseWRT = JSON.parse(data);
                                        if (responseWRT.status === 200) {
                                            informacion.status = responseWRT.status;
                                            informacion.payload = responseWRT.res.payload;
                                            evento('Captura Video Token WebRTC', 'Success', informacion, true);
                                            if (responseWRT.res.payload.match) {
                                                that.setState({ loading: false })
                                                that.setLocalStorageResultFace(responseWRT.res.payload)
                                                that.sendData(responseWRT.res.payload.match)
                                                setTimeout(() => {
                                                    that.props.history("/finalizado");
                                                }, 300);
                                            } else {
                                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null })
                                                if (that.state.intents === that.state.intentos) {
                                                    that.finalizarFlujo(informacion);
                                                } else {
                                                        that.statusR();
                                                }
                                            }
                                            that.setState({ loading: false, intents: 0, hasResponse: true })
                                            peerConnection.close()
                                        } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                            let errorMessage = that.msjsError(responseWRT.res.message_client);
                                            if (responseWRT.res.message_client === 'No se encontro rostro' || responseWRT.res.message_client === 'Se detecto cubrebocas' || responseWRT.res.message_client === 'Condiciones de luz no adecuadas' || responseWRT.res.message_client === 'Imagen borrosa' || responseWRT.res.message_client === 'Se detectaron multiples caras' || responseWRT.res.message_client === 'Falta de luz' || responseWRT.res.message_client === 'No se pudo realizar la comparacion de rostro') {
                                                that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, hasResponse: true })
                                            } else {
                                                that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true });
                                                await statusReintento('Intentos Video Token', that.state.intents, 'RETRY');
                                            }
                                            informacion.mensaje = responseWRT.res.message_client;
                                            informacion.status = responseWRT.status;
                                            evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                            if (that.state.intents === that.state.intentos) {
                                                that.finalizarFlujo(informacion);
                                            } else {
                                                    that.statusR();
                                            }
                                            peerConnection.close()
                                        } else if (responseWRT.status === 404) {
                                            that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true });
                                            await statusReintento('Intentos Video Token', that.state.intents, 'RETRY');
                                            if (responseWRT.res.message_client === "Spoof detected") {
                                                that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")) });
                                            }
                                            informacion.mensaje = responseWRT.res.message_client;
                                            informacion.status = responseWRT.status;
                                            evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                            if (that.state.intents === that.state.intentos) {
                                                that.finalizarFlujo(informacion);
                                            } else {
                                                    that.statusR();
                                            }
                                            peerConnection.close()
                                        } else {
                                            that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, hasResponse: true });
                                            informacion.mensaje = responseWRT.res.message_client;
                                            informacion.status = responseWRT.status;
                                            evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                            if (that.state.intents === that.state.intentos) {
                                                that.finalizarFlujo(informacion);
                                            } else {
                                                    that.statusR();
                                            }
                                            peerConnection.close()

                                        }
                                        that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                                        that.titulo.className = "cnt_dwn animate__animated";
                                        localVideo.classList.remove("blur_video")
                                    }
                                    let intentos = 0
                                    function onDataChannel({ channel }) {
                                        if (channel.label !== 'ping-pong') {
                                            return;
                                        }
                                        dataChannel = channel;
                                        dataChannel.addEventListener('message', onMessage);
                                        interval = setInterval(() => {
                                            intentos++;
                                            if (intentos === 1) {
                                                that.titulo.className = "animate__animated animate__fadeOut";
                                                that.setState({ showNumbers: true });
                                            }
                                            if (intentos === 2) {
                                                that.titulo.className = "token_nmbr row";
                                                let html = '';
                                                if (isMobile) {
                                                    html = '<div id="left" class="div_left_first_mobile"></div>' +
                                                        '<div id="first" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                                                } else {
                                                    html = '<div id="left" class="div_left_first"></div>' +
                                                        '<div id="first" class="div_center centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                                                }
                                                that.titulo.innerHTML = html;
                                            }
                                            if (intentos === 3) {
                                                let divFirst = document.querySelector('#first');
                                                let divleft = document.querySelector('#left');
                                                if (!divFirst || !divleft) return;
                                                divFirst.classList.remove('animate__slideInDown');
                                                divFirst.classList.add('animate__backOutLeft');
                                                setTimeout(() => {
                                                    let html = '';
                                                    if (isMobile) {
                                                        divleft.className = "div_left_second_mobile";
                                                        divFirst.classList.remove('centro', 'animate__backOutLeft');
                                                        divFirst.classList.add('lateral_mobile');
                                                        html = '<div id="second" class="div_center_mobile centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                                    } else {
                                                        divleft.className = "div_left_second";
                                                        divFirst.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                        divFirst.classList.add('lateral');
                                                        html = '<div id="second" class="div_center centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                                    }
                                                    that.titulo.innerHTML += html;
                                                }, 220);
                                            }
                                            if (intentos === 4) {
                                                let divSecond = document.querySelector('#second');
                                                let divleft = document.querySelector('#left');
                                                if (!divSecond || !divleft) return;
                                                divSecond.classList.remove('animate__slideInDown');
                                                divSecond.classList.add('animate__backOutLeft');
                                                setTimeout(() => {
                                                    let html = '';
                                                    if (isMobile) {
                                                        divleft.className = "div_left_third_mobile";
                                                        divSecond.classList.remove('centro', 'animate__backOutLeft');
                                                        divSecond.classList.add('lateral_mobile');
                                                        html = '<div id="third" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                                    } else {
                                                        divleft.className = "div_left_third";
                                                        divSecond.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                        divSecond.classList.add('lateral');
                                                        html = '<div id="third" class="div_center centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                                    }
                                                    that.titulo.innerHTML += html;
                                                }, 220);
                                            }
                                            if (intentos === 5) {
                                                let divThird = document.querySelector('#third');
                                                let divleft = document.querySelector('#left');
                                                if (!divThird || !divleft) return;
                                                divThird.classList.remove('animate__slideInDown');
                                                divThird.classList.add('animate__backOutLeft');
                                                setTimeout(() => {
                                                    let html = '';
                                                    if (isMobile) {
                                                        divleft.className = "div_left_fourth_mobile";
                                                        divThird.classList.remove('centro', 'animate__backOutLeft');
                                                        divThird.classList.add('lateral_mobile');
                                                        html = '<div id="fourth" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                                    } else {
                                                        divleft.className = "div_left_fourth";
                                                        divThird.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                        divThird.classList.add('lateral');
                                                        html = '<div id="fourth" class="div_center centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                                    }
                                                    that.titulo.innerHTML += html;
                                                }, 220);
                                            }
                                            if (intentos === 6) {
                                                let divFirst = document.querySelector('#first');
                                                let divFourth = document.querySelector('#fourth');
                                                let divleft = document.querySelector('#left');
                                                if (!divFirst || !divFourth || !divleft) return;
                                                divFourth.classList.remove('animate__slideInDown');
                                                divFourth.classList.add('animate__backOutLeft');
                                                setTimeout(() => {
                                                    let html = '';
                                                    if (isMobile) {
                                                        divFirst.classList.add('animate__fadeOutLeft');
                                                        divFirst.parentNode.removeChild(divFirst);
                                                        divleft.className = "div_left_fifth_mobile";
                                                        divFourth.classList.remove('centro', 'animate__backOutLeft');
                                                        divFourth.classList.add('lateral_mobile');
                                                        html = '<div id="fifth" class="div_center_mobile centro animate__animated animate__slideInDown"> </div>';
                                                    } else {
                                                        divleft.className = "div_left_fifth";
                                                        divFourth.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                        divFourth.classList.add('lateral');
                                                        html = '<div id="fifth" class="div_center centro animate__animated animate__slideInDown"> </div>';
                                                    }
                                                    that.titulo.innerHTML += html;
                                                }, 220);
                                            }
                                            if (intentos === 8) {
                                                that.titulo.innerHTML = '<p class="animate__animated animate__slideInDown">Procesando. <b>Gracias</b> por tu paciencia.</p>';
                                                that.titulo.className = "txt_videotoken";
                                                that.setState({ isRecording: false, isComplete: true, loading: true });
                                                dataChannel.send("finish_record");
                                                localVideo.classList.add("blur_video")
                                                that.setState({ loading: false, hasResponse: false })
                                                that.contador();
                                            }
                                        }, 1000);
                                        timeoutt = setTimeout(() => {
                                            const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
                                            let passFlag = "false"
                                            if (ocrFront != null) {
                                                if (ocrFront.identificacionPasaporte) {
                                                    passFlag = "true"
                                                }
                                            }
                                            dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service: 'videotoken-embedding', identificacion_pasaporte: passFlag }));
                                        }, 500);
                                    }
                                    peerConnection.addEventListener('datachannel', onDataChannel);
                                    const { close } = peerConnection;
                                    peerConnection.close = function () {
                                        if (dataChannel) {
                                            dataChannel.removeEventListener('message', onMessage);
                                        }
                                        if (interval) {
                                            clearInterval(interval);
                                        }
                                        if (timeoutt) {
                                            clearTimeout(timeoutt)
                                        }
                                        return close.apply(this, arguments);
                                    };
                                }

                                peerConnection1 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                                window.peerConnection1 = peerConnection1;
                            }, 7000)


                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            let { data: { message_client }, status } = error.response
                            informacion.mensaje = message_client;
                            informacion.status = status;
                            tipo = 'Error';
                            if (error.error_code === 'SVC_ERROR_CONNECT"') {
                                that.setState({ errorMessage: "El servicio no se encuentra disponible.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true, stable: false })
                            } else {
                                that.setState({ errorMessage: "Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true, stable: false })
                            }
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                            that.setState({ errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true, stable: false })
                        }
                        evento('OTP', tipo, informacion, false);
                        if (that.state.intents === that.state.intentos) {
                            that.finalizarFlujo(informacion);
                        } else {
                                that.statusR();
                        }
                    }
                    )
            }, 'image/jpeg', 0.9);
        }, 1000);
    }

    takePhotoAndroid = () => {
        evento('Captura Video Token', 'MediaRecorder', { description: 'OK' }, true);
        let tipo = '';
        let informacion = {};
        //console.log("Entraste a takePhotoAndroid");
        setTimeout(() => {
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                this.setState({ sendingOTP: false });
                enviarImagen(that.state.uuid, blob)
                    .then((response) => {
                        //console.log("Esta haciendo la petición, si es iggual a 200 entra al siguiente if");
                        if (response.status === 200) {
                            informacion.status = response.status;
                            informacion.payload = response.data.payload;
                            evento('OTP', 'Success', informacion, true);
                            let listCodes = response.data.payload.codes;
                            that.setState({ codes: listCodes });
                            localStorage.setItem("codes", JSON.stringify(listCodes));

                            setTimeout(() => {
                                if (that.state.stable) {
                                    let div = document.getElementById("div-stable");
                                    if (!div) return;
                                    div.className = "animate__animated animate__fadeOut";
                                }
                                that.elemento.className = "animate__animated animate__fadeOut";
                                setTimeout(() => {
                                    that.setState({ stable: false });
                                    that.elemento.className = "animate__animated";
                                    that.titulo.classList.add('animate__slideInDown');
                                    that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                                }, 1000);
                            }, 5000);

                            setTimeout(async () => {
                                let tokenLS = listCodes.pop();
                                that.setState({ token: tokenLS });
                                localStorage.setItem("codes", JSON.stringify(listCodes));
                                const token = tokenLS.code;
                                const Acreated = tokenLS.created;
                                const Aexpire = tokenLS.expire;
                                const localVideo = document.getElementById('video_wrt_environment');
                                if (!localVideo) return;
                                localVideo.autoplay = true;
                                localVideo.muted = true;

                                that.recorder.startRecording();

                                let intentos = 0;
                                let blobvideo = null

                                let interval = setInterval(() => {
                                    intentos++;
                                    if (intentos === 1) {
                                        that.titulo.className = "animate__animated animate__fadeOut";
                                        that.setState({ showNumbers: true });
                                    }
                                    if (intentos === 2) {
                                        that.titulo.className = "token_nmbr row";
                                        let html = '';
                                        if (isMobile) {
                                            html = '<div id="left" class="div_left_first_mobile"></div>' +
                                                '<div id="first" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                                        } else {
                                            html = '<div id="left" class="div_left_first"></div>' +
                                                '<div id="first" class="div_center centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                                        }
                                        that.titulo.innerHTML = html;
                                    }
                                    if (intentos === 3) {
                                        let divFirst = document.querySelector('#first');
                                        let divleft = document.querySelector('#left');
                                        if (!divFirst || !divleft) return;
                                        divFirst.classList.remove('animate__slideInDown');
                                        divFirst.classList.add('animate__backOutLeft');
                                        setTimeout(() => {
                                            let html = '';
                                            if (isMobile) {
                                                divleft.className = "div_left_second_mobile";
                                                divFirst.classList.remove('centro', 'animate__backOutLeft');
                                                divFirst.classList.add('lateral_mobile');
                                                html = '<div id="second" class="div_center_mobile centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                            } else {
                                                divleft.className = "div_left_second";
                                                divFirst.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                divFirst.classList.add('lateral');
                                                html = '<div id="second" class="div_center centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                            }
                                            that.titulo.innerHTML += html;
                                        }, 220);
                                    }
                                    if (intentos === 4) {
                                        let divSecond = document.querySelector('#second');
                                        let divleft = document.querySelector('#left');
                                        if (!divSecond || !divleft) return;
                                        divSecond.classList.remove('animate__slideInDown');
                                        divSecond.classList.add('animate__backOutLeft');
                                        setTimeout(() => {
                                            let html = '';
                                            if (isMobile) {
                                                divleft.className = "div_left_third_mobile";
                                                divSecond.classList.remove('centro', 'animate__backOutLeft');
                                                divSecond.classList.add('lateral_mobile');
                                                html = '<div id="third" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                            } else {
                                                divleft.className = "div_left_third";
                                                divSecond.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                divSecond.classList.add('lateral');
                                                html = '<div id="third" class="div_center centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                            }
                                            that.titulo.innerHTML += html;
                                        }, 220);

                                    }
                                    if (intentos === 5) {
                                        let divThird = document.querySelector('#third');
                                        let divleft = document.querySelector('#left');
                                        if (!divThird || !divleft) return;
                                        divThird.classList.remove('animate__slideInDown');
                                        divThird.classList.add('animate__backOutLeft');
                                        setTimeout(() => {
                                            let html = '';
                                            if (isMobile) {
                                                divleft.className = "div_left_fourth_mobile";
                                                divThird.classList.remove('centro', 'animate__backOutLeft');
                                                divThird.classList.add('lateral_mobile');
                                                html = '<div id="fourth" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                            } else {
                                                divleft.className = "div_left_fourth";
                                                divThird.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                divThird.classList.add('lateral');
                                                html = '<div id="fourth" class="div_center centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                            }
                                            that.titulo.innerHTML += html;
                                        }, 220);
                                    }
                                    if (intentos === 6) {
                                        let divFirst = document.querySelector('#first');
                                        let divFourth = document.querySelector('#fourth');
                                        let divleft = document.querySelector('#left');
                                        if (!divFirst || !divFourth || !divleft) return;
                                        divFourth.classList.remove('animate__slideInDown');
                                        divFourth.classList.add('animate__backOutLeft');
                                        setTimeout(() => {
                                            let html = '';
                                            if (isMobile) {
                                                divFirst.classList.add('animate__fadeOutLeft');
                                                divFirst.parentNode.removeChild(divFirst);
                                                divleft.className = "div_left_fifth_mobile";
                                                divFourth.classList.remove('centro', 'animate__backOutLeft');
                                                divFourth.classList.add('lateral_mobile');
                                                html = '<div id="fifth" class="div_center_mobile centro animate__animated animate__slideInDown"> </div>';
                                            } else {
                                                divleft.className = "div_left_fifth";
                                                divFourth.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                                divFourth.classList.add('lateral');
                                                html = '<div id="fifth" class="div_center centro animate__animated animate__slideInDown"> </div>';
                                            }
                                            that.titulo.innerHTML += html;
                                        }, 220);
                                    }
                                    if (intentos === 8) {
                                        that.recorder.stopRecording(function () {
                                            blobvideo = that.recorder.getBlob();
                                            //that.downloadVideo(blobvideo);
                                            let contextCanvas = that.canvas.getContext('2d');
                                            contextCanvas.drawImage(that.video, 0, 0, that.video.videoWidth * 1.5, that.video.videoHeight * 1.5);
                                            that.canvas.toBlob((blob) => {
                                                console.log("blob",blob);
                                                that.setState({imgBlob : blob});
                                                that.sendVideoCall(blobvideo, Acreated, Aexpire, isIOS)
                                            }, 'image/jpeg', 0.9);
                                        });
                                    }
                                    if (intentos === 9) {
                                        that.titulo.innerHTML = '<p class="animate__animated animate__slideInDown">Procesando. <b>Gracias</b> por tu paciencia.</b></p>';
                                        that.titulo.className = "txt_videotoken";
                                        that.setState({ isRecording: false, isComplete: true, loading: true });
                                        localVideo.classList.add("blur_video")
                                        that.setState({ loading: false, hasResponse: false });
                                        that.contador();
                                        clearInterval(interval)
                                    }
                                }, 1000);
                            }, 7000);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            let { data: { message_client }, status } = error.response
                            informacion.mensaje = message_client;
                            informacion.status = status;
                            tipo = 'Error';
                            if (error.error_code === 'SVC_ERROR_CONNECT"') {
                                that.setState({ errorMessage: "El servicio no se encuentra disponible.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                            } else if (status === 504) {
                                that.setState({ errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                            } else {
                                that.setState({ errorMessage: "Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                            }
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                            that.setState({ errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                        }
                        evento('OTP', tipo, informacion, false);
                        if (that.state.intents === that.state.intentos) {
                            that.finalizarFlujo(informacion);
                        } else {
                                that.statusR();
                        }
                    })

            }, 'image/jpeg', 0.9);
        }, 3000);
    }

    downloadVideo = (blobvideo) => {
        let video = document.createElement('video');
        video.srcObject = blobvideo;
        // console.log("time1", video.duration);
        video.onloadedmetadata = function (e) {
            let url = URL.createObjectURL(blobvideo);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = isIOS ? "test.mp4" : "test.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            // console.log("time2", video.duration);
        }
    }

    obtenerToken = async (reIntento) => {
        let description = reIntento ? 'REINTENTAR' : 'CONTINUAR';
        evento('Captura Video Token', 'Click', { description: description }, true);
        let tipo = '';
        let informacion = {};
        let time = 7000;
        if (!reIntento) {
            this.titulo.className = "animate__animated animate__slideInDown";
            this.titulo.innerHTML = "<p>Ubica tu rostro de <b>frente </b>para empezar</p>";
            setTimeout(() => {
                this.setState({ stable: true });
                this.titulo.classList.remove('animate__slideInDown');
            }, 2000);
        } else {
            this.titulo.classList.remove('animate__slideInDown');
            time = 2000;
        }
        this.setState({ loading: false, errorToken: false, errorMessage: "" })
        if (localStorage.getItem("bloqueo") === "false") {
            this.setState({ conteo: true, isStart: false })
            let codigos = localStorage.getItem("codes") ? JSON.parse(localStorage.getItem("codes")) : [];
            if (codigos.length === 0) {
                if (this.state.suportMediaRecorder) {
                    this.takePhotoAndroid();
                } else {
                    this.takepicture();
                }
            }
            else if (!this.state.suportMediaRecorder) {
                if (!reIntento) {
                    setTimeout(() => {
                        if (this.state.stable) {
                            let div = document.getElementById("div-stable");
                            if (!div) return;
                            div.className = "animate__animated animate__fadeOut";
                        }
                        this.elemento.className = "animate__animated animate__fadeOut";
                        setTimeout(() => {
                            this.setState({ stable: false });
                            this.elemento.className = "animate__animated";
                            this.titulo.classList.add('animate__slideInDown');
                            this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                        }, 1000);
                    }, 5000);
                } else {
                    this.titulo.classList.add('animate__animated', 'animate__slideInDown');
                    this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                }
                setTimeout(async () => {

                    this.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true })

                    let tokens_ls = JSON.parse(localStorage.getItem("codes"))
                    let tokenLS = tokens_ls.pop()
                    this.setState({ token: tokenLS });
                    localStorage.setItem("codes", JSON.stringify(tokens_ls));
                    const token = tokenLS.code;
                    const connectionClient = new ConnectionClient();
                    let peerConnection2 = null;
                    const that = this;

                    const localVideo = document.getElementById('video_wrt_environment');
                    if (!localVideo) return;
                    localVideo.autoplay = true;
                    localVideo.muted = true;

                    async function beforeAnswer(peerConnection) {
                        that.localStream.then(function (mediaStream) {
                            mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                        })
                        peerConnection2 = peerConnection;
                        let dataChannel = null;
                        let interval = null;
                        let timeoutt = null;
                        async function onMessage({ data }) {
                            const responseWRT = JSON.parse(data);
                            if (responseWRT.status === 200) {
                                informacion.status = responseWRT.status;
                                informacion.payload = responseWRT.res.payload;
                                evento('Captura Video Token WebRTC', 'Success', informacion, true);
                                if (responseWRT.res.payload.match) {
                                    //console.log("responseWRT: ", responseWRT.res);
                                    that.setState({ loading: false })
                                    that.setLocalStorageResultFace(responseWRT.res.payload)
                                    that.sendData(responseWRT.res.payload.match)
                                    setTimeout(() => {
                                        that.props.history("/finalizado");
                                    }, 300);
                                } else {
                                    that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null })
                                    if (that.state.intents === that.state.intentos) {
                                        that.finalizarFlujo(informacion);
                                    } else {
                                            that.statusR();
                                    }
                                }
                                that.setState({ loading: false, intents: 0, hasResponse: true })
                                peerConnection.close()
                            } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                let errorMessage = that.msjsError(responseWRT.res.message_client);
                                if (responseWRT.res.message_client === 'No se encontro rostro' || responseWRT.res.message_client === 'Se detecto cubrebocas' || responseWRT.res.message_client === 'Condiciones de luz no adecuadas' || responseWRT.res.message_client === 'Imagen borrosa' || responseWRT.res.message_client === 'Se detectaron multiples caras' || responseWRT.res.message_client === 'Falta de luz' || responseWRT.res.message_client === 'No se pudo realizar la comparacion de rostro') {
                                    that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, hasResponse: true })
                                } else {
                                    that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true });
                                    await statusReintento('Intentos Video Token', that.state.intents, 'RETRY');
                                }
                                informacion.mensaje = responseWRT.res.message_client;
                                informacion.status = responseWRT.status;
                                evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                if (that.state.intents === that.state.intentos) {
                                    that.finalizarFlujo(informacion);
                                } else {
                                        that.statusR();
                                }
                                peerConnection.close()
                            } else if (responseWRT.status === 404) {
                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true });
                                await statusReintento('Intentos Video Token', that.state.intents, 'RETRY');
                                if (responseWRT.res.message_client === "Spoof detected") {
                                    that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")) });
                                }
                                informacion.mensaje = responseWRT.res.message_client;
                                informacion.status = responseWRT.status;
                                evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                if (that.state.intents === that.state.intentos) {
                                    that.finalizarFlujo(informacion);
                                } else {
                                        that.statusR();
                                }
                                peerConnection.close()
                            } else {
                                that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, hasResponse: true })
                                informacion.mensaje = responseWRT.res.message_client;
                                informacion.status = responseWRT.status;
                                evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                if (that.state.intents === that.state.intentos) {
                                    that.finalizarFlujo(informacion);
                                } else {
                                        that.statusR();
                                }
                                peerConnection.close()
                            }
                            that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";

                            localVideo.classList.remove("blur_video")
                        }
                        let intentos = 0
                        function onDataChannel({ channel }) {
                            if (channel.label !== 'ping-pong') {
                                return;
                            }
                            dataChannel = channel;
                            dataChannel.addEventListener('message', onMessage);
                            interval = setInterval(() => {
                                intentos++;
                                if (intentos === 1) {
                                    that.titulo.className = "animate__animated animate__fadeOut";
                                    that.setState({ showNumbers: true });
                                }
                                if (intentos === 2) {
                                    that.titulo.className = "token_nmbr row";
                                    let html = '';
                                    if (isMobile) {
                                        html = '<div id="left" class="div_left_first_mobile"></div>' +
                                            '<div id="first" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                                    } else {
                                        html = '<div id="left" class="div_left_first"></div>' +
                                            '<div id="first" class="div_center centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                                    }
                                    that.titulo.innerHTML = html;
                                }
                                if (intentos === 3) {
                                    let divFirst = document.querySelector('#first');
                                    let divleft = document.querySelector('#left');
                                    if (!divFirst || !divleft) return;
                                    divFirst.classList.remove('animate__slideInDown');
                                    divFirst.classList.add('animate__backOutLeft');
                                    setTimeout(() => {
                                        let html = '';
                                        if (isMobile) {
                                            divleft.className = "div_left_second_mobile";
                                            divFirst.classList.remove('centro', 'animate__backOutLeft');
                                            divFirst.classList.add('lateral_mobile');
                                            html = '<div id="second" class="div_center_mobile centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                        } else {
                                            divleft.className = "div_left_second";
                                            divFirst.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                            divFirst.classList.add('lateral');
                                            html = '<div id="second" class="div_center centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                        }
                                        that.titulo.innerHTML += html;
                                    }, 220);
                                }
                                if (intentos === 4) {

                                    let divSecond = document.querySelector('#second');
                                    let divleft = document.querySelector('#left');
                                    if (!divSecond || !divleft) return;
                                    divSecond.classList.remove('animate__slideInDown');
                                    divSecond.classList.add('animate__backOutLeft');
                                    setTimeout(() => {
                                        let html = '';
                                        if (isMobile) {
                                            divleft.className = "div_left_third_mobile";
                                            divSecond.classList.remove('centro', 'animate__backOutLeft');
                                            divSecond.classList.add('lateral_mobile');
                                            html = '<div id="third" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                        } else {
                                            divleft.className = "div_left_third";
                                            divSecond.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                            divSecond.classList.add('lateral');
                                            html = '<div id="third" class="div_center centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                        }
                                        that.titulo.innerHTML += html;
                                    }, 220);

                                }
                                if (intentos === 5) {

                                    let divThird = document.querySelector('#third');
                                    let divleft = document.querySelector('#left');
                                    if (!divThird || !divleft) return;
                                    divThird.classList.remove('animate__slideInDown');
                                    divThird.classList.add('animate__backOutLeft');
                                    setTimeout(() => {
                                        let html = '';
                                        if (isMobile) {
                                            divleft.className = "div_left_fourth_mobile";
                                            divThird.classList.remove('centro', 'animate__backOutLeft');
                                            divThird.classList.add('lateral_mobile');
                                            html = '<div id="fourth" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                        } else {
                                            divleft.className = "div_left_fourth";
                                            divThird.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                            divThird.classList.add('lateral');
                                            html = '<div id="fourth" class="div_center centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                        }
                                        that.titulo.innerHTML += html;
                                    }, 220);
                                }
                                if (intentos === 6) {

                                    let divFirst = document.querySelector('#first');
                                    let divFourth = document.querySelector('#fourth');
                                    let divleft = document.querySelector('#left');
                                    if (!divFirst || !divFourth || !divleft) return;
                                    divFourth.classList.remove('animate__slideInDown');
                                    divFourth.classList.add('animate__backOutLeft');
                                    setTimeout(() => {
                                        let html = '';
                                        if (isMobile) {
                                            divFirst.classList.add('animate__fadeOutLeft');
                                            divFirst.parentNode.removeChild(divFirst);
                                            divleft.className = "div_left_fifth_mobile";
                                            divFourth.classList.remove('centro', 'animate__backOutLeft');
                                            divFourth.classList.add('lateral_mobile');
                                            html = '<div id="fifth" class="div_center_mobile centro animate__animated animate__slideInDown"> </div>';
                                        } else {
                                            divleft.className = "div_left_fifth";
                                            divFourth.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                            divFourth.classList.add('lateral');
                                            html = '<div id="fifth" class="div_center centro animate__animated animate__slideInDown"> </div>';
                                        }
                                        that.titulo.innerHTML += html;
                                    }, 220);
                                }
                                if (intentos === 8) {
                                    that.titulo.innerHTML = '<p class="animate__animated animate__slideInDown">Procesando. <b>Gracias</b> por tu <b>paciencia</b>.</p>';
                                    that.titulo.className = "txt_videotoken";
                                    that.setState({ isRecording: false, isComplete: true, loading: true });
                                    dataChannel.send("finish_record");
                                    localVideo.classList.add("blur_video");
                                    that.setState({ loading: false, hasResponse: false });
                                    that.contador();
                                }
                            }, 1000);
                            timeoutt = setTimeout(() => {
                                const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
                                let passFlag = "false"
                                if (ocrFront != null) {
                                    if (ocrFront.identificacionPasaporte) {
                                        passFlag = "true"
                                    }
                                }
                                dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service: 'videotoken-embedding', identificacion_pasaporte: passFlag }));
                            }, 500);
                        }
                        peerConnection.addEventListener('datachannel', onDataChannel);
                        const { close } = peerConnection;
                        peerConnection.close = function () {
                            if (dataChannel) {
                                dataChannel.removeEventListener('message', onMessage);
                            }
                            if (interval) {
                                clearInterval(interval);
                            }
                            if (timeoutt) {
                                clearTimeout(timeoutt)
                            }
                            return close.apply(this, arguments);
                        };
                    }

                    peerConnection2 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                    window.peerConnection2 = peerConnection2;
                }, time)
            }
            else {
                // console.log("Entró a: obtenerToken => takePhotoAndroid.", "Tu sistema operativo es IOS? = ", isIOS);
                const that = this;

                if (!reIntento) {
                    setTimeout(() => {
                        if (that.state.stable) {
                            let div = document.getElementById("div-stable");
                            if (!div) return;
                            div.className = "animate__animated animate__fadeOut";
                        }
                        that.elemento.className = "animate__animated animate__fadeOut";
                        setTimeout(() => {
                            that.setState({ stable: false });
                            that.elemento.className = "animate__animated";
                            that.titulo.classList.add('animate__slideInDown');
                            that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                        }, 1000);
                    }, 5000);
                } else {
                    that.titulo.classList.add('animate__animated', 'animate__slideInDown');
                    that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro, al aparecer</p>";
                }

                let codigosLS = JSON.parse(localStorage.getItem("codes"))
                let tokenLS = codigosLS.pop();
                that.setState({ token: tokenLS });
                localStorage.setItem("codes", JSON.stringify(codigosLS));

                const token = tokenLS.code;
                const Acreated = tokenLS.created;
                const Aexpire = tokenLS.expire;

                const localVideo = document.getElementById('video_wrt_environment');
                if (!localVideo) return;
                localVideo.autoplay = true;
                localVideo.muted = true;

                setTimeout(async () => {
                    // console.log("Estamos dentro del constructor de numeros aleatorios");
                    that.recorder.startRecording();
                    let intentos = 0;
                    let blobvideo = null
                    let interval = setInterval(() => {
                        intentos++;

                        if (intentos === 1) {
                            that.titulo.className = "animate__animated animate__fadeOut";
                            that.setState({ showNumbers: true });
                            that.titulo.innerHTML = "";
                        }
                        if (intentos === 2) {
                            that.titulo.className = "token_nmbr row";
                            let html = '';

                            if (isMobile) {
                                html = '<div id="left" class="div_left_first_mobile"></div>' +
                                    '<div id="first" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                            } else {
                                html = '<div id="left" class="div_left_first"></div>' +
                                    '<div id="first" class="div_center centro animate__animated animate__slideInDown">' + token.substring(0, 1) + '</div>';
                            }
                            that.titulo.innerHTML = html;
                        }
                        if (intentos === 3) {

                            let divFirst = document.querySelector('#first');
                            let divleft = document.querySelector('#left');
                            if (!divFirst || !divleft) return;
                            divFirst.classList.remove('animate__slideInDown');
                            divFirst.classList.add('animate__backOutLeft');
                            setTimeout(() => {
                                let html = '';
                                if (isMobile) {
                                    divleft.className = "div_left_second_mobile";
                                    divFirst.classList.remove('centro', 'animate__backOutLeft');
                                    divFirst.classList.add('lateral_mobile');
                                    html = '<div id="second" class="div_center_mobile centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                } else {
                                    divleft.className = "div_left_second";
                                    divFirst.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                    divFirst.classList.add('lateral');
                                    html = '<div id="second" class="div_center centro animate__animated animate__slideInDown ">' + token.substring(1, 2) + '</div>';
                                }
                                that.titulo.innerHTML += html;
                            }, 220);
                        }
                        if (intentos === 4) {
                            let divSecond = document.querySelector('#second');
                            let divleft = document.querySelector('#left');
                            if (!divSecond || !divleft) return;
                            divSecond.classList.remove('animate__slideInDown');
                            divSecond.classList.add('animate__backOutLeft');

                            setTimeout(() => {
                                let html = '';
                                if (isMobile) {
                                    divleft.className = "div_left_third_mobile";
                                    divSecond.classList.remove('centro', 'animate__backOutLeft');
                                    divSecond.classList.add('lateral_mobile');
                                    html = '<div id="third" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                } else {
                                    divleft.className = "div_left_third";
                                    divSecond.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                    divSecond.classList.add('lateral');
                                    html = '<div id="third" class="div_center centro animate__animated animate__slideInDown">' + token.substring(2, 3) + '</div>';
                                }
                                that.titulo.innerHTML += html;
                            }, 220);

                        }
                        if (intentos === 5) {

                            let divThird = document.querySelector('#third');
                            let divleft = document.querySelector('#left');
                            if (!divThird || !divleft) return;
                            divThird.classList.remove('animate__slideInDown');
                            divThird.classList.add('animate__backOutLeft');

                            setTimeout(() => {
                                let html = '';
                                if (isMobile) {
                                    divleft.className = "div_left_fourth_mobile";
                                    divThird.classList.remove('centro', 'animate__backOutLeft');
                                    divThird.classList.add('lateral_mobile');
                                    html = '<div id="fourth" class="div_center_mobile centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                } else {
                                    divleft.className = "div_left_fourth";
                                    divThird.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                    divThird.classList.add('lateral');
                                    html = '<div id="fourth" class="div_center centro animate__animated animate__slideInDown">' + token.substring(3, 4) + '</div>';
                                }
                                that.titulo.innerHTML += html;
                            }, 220);
                        }
                        if (intentos === 6) {

                            let divFirst = document.querySelector('#first');
                            let divFourth = document.querySelector('#fourth');
                            let divleft = document.querySelector('#left');
                            if (!divFirst || !divFourth || !divleft) return;
                            divFourth.classList.remove('animate__slideInDown');
                            divFourth.classList.add('animate__backOutLeft');

                            setTimeout(() => {
                                let html = '';
                                if (isMobile) {
                                    divFirst.classList.add('animate__fadeOutLeft');
                                    divFirst.parentNode.removeChild(divFirst);
                                    divleft.className = "div_left_fifth_mobile";
                                    divFourth.classList.remove('centro', 'animate__backOutLeft');
                                    divFourth.classList.add('lateral_mobile');
                                    html = '<div id="fifth" class="div_center_mobile centro animate__animated animate__slideInDown"> </div>';
                                } else {
                                    divleft.className = "div_left_fifth";
                                    divFourth.classList.remove('div_center', 'centro', 'animate__backOutLeft');
                                    divFourth.classList.add('lateral');
                                    html = '<div id="fifth" class="div_center centro animate__animated animate__slideInDown"> </div>';
                                }
                                that.titulo.innerHTML += html;
                            }, 220);
                        }
                        if (intentos === 8) {
                            that.recorder.stopRecording(function () {
                                blobvideo = that.recorder.getBlob();
                                //that.downloadVideo(blobvideo);
                                let contextCanvas = that.canvas.getContext('2d');
                                contextCanvas.drawImage(that.video, 0, 0, that.video.videoWidth * 1.5, that.video.videoHeight * 1.5);
                                that.canvas.toBlob((blob) => {
                                    console.log("blob",blob);
                                    that.setState({imgBlob : blob});
                                    that.sendVideoCall(blobvideo, Acreated, Aexpire, isIOS)
                                }, 'image/jpeg', 0.9);
                            });
                        }
                        if (intentos === 9) {
                            that.titulo.innerHTML = '<p class="animate__animated animate__slideInDown">Procesando. <b>Gracias</b> por tu <b>paciencia</b>.</p>';
                            that.titulo.className = "txt_videotoken";
                            that.setState({ isRecording: false, isComplete: true, loading: true });
                            localVideo.classList.add("blur_video")
                            that.setState({ loading: false, hasResponse: false });
                            that.contador();
                            clearInterval(interval)
                        }
                    }, 1000);
                }, time);
            }

        } else {
            this.setState({ isStart: false })
        }
    }

    sendVideoCall = (blobvideo, Acreated, Aexpire, isIOS) => {
        let tipo = '';
        let informacion = {};
        enviarVideoRostro(blobvideo, Acreated, Aexpire, isIOS)
            .then((responseWRT) => {
                if (responseWRT.status === 200) {
                    informacion.status = responseWRT.status;
                    informacion.payload = responseWRT.data.payload;
                    evento('Captura Video Token', 'Success', informacion, true);
                    this.sendData(responseWRT.data.payload.match)
                    this.setLocalStorageResultFace(responseWRT.data.payload);
                    let data_user = JSON.parse(localStorage.getItem('data_user'))
                    let curp = obtenerValorUser(data_user, "curp", "");
                    console.log("curp",curp);
                    console.log("this.state.imgBlob",this.state.imgBlob);
                    validarRegistro(curp, this.state.imgBlob)
                        .then((response) => {
                            if (response.status === 200) {
                                console.log("response",response);
                                localStorage.setItem("trx",response.data.transaction_code)
                                setTimeout(() => {
                                    //this.props.history.push("/finalizado");
                                    window.location.href = "/finalizado";
                                }, 100);
                            }
                        })
                        .catch((error) => {
                            //evento('Captura Video Token', tipo, informacion, false);
                            console.log("Error:", error.response);
                        })
                        .finally(() => {
                            this.setState({ hasResponse: true })
                            this.setState({ loading: false})
                        })
                }
            })
            .catch(async (error) => {
                if (error.response) {
                    let { data: { message_client }, status } = error.response
                    informacion.mensaje = message_client;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Captura Video Token', tipo, informacion, false);
                if (error.response) {
                    let mensaje = "";
                    let errorMessage = '';
                    if (error.response.data) {
                        mensaje = error.response.data.message_client
                    }
                    errorMessage = this.msjsError(mensaje);
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro') {
                            this.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, hasResponse: true })
                        } else {
                            this.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, intents: this.state.intents + 1, hasResponse: true });
                            await statusReintento('Intentos Video Token', this.state.intents, 'RETRY');
                        }
                    } else if (error.response.status === 404) {
                        this.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, intents: this.state.intents + 1, hasResponse: true });
                        await statusReintento('Intentos Video Token', this.state.intents, 'RETRY');
                    } else {
                        this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, hasResponse: true })
                    }
                } else {
                    if (error.toString().includes('Network')) {
                        this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, hasResponse: true })
                    } else {
                        this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, hasResponse: true })
                    }
                }
                this.setState({ errorCapture: true });
                let video = document.getElementById('video_wrt_environment');
                if (video) {
                    video.classList.remove("blur_video");
                } else {
                    return;
                }
                if (this.state.intents === this.state.intentos) {
                    this.finalizarFlujo(informacion);
                } else {
                    this.statusR();
                }
            })
    }

    setLocalStorageResultFace = (result) => {
        let face = {}
        //console.log("responseWRT: ", result);
        if (result.match) {
            face = {
                match: result.match,
                /* gender: (result.faceComparison.payload.Query[0].gender) === "male" ? "Masculino" : "Femenino",
                age: result.faceComparison.payload.Query[0].age.toFixed(0),
                expressions: result.faceComparison.payload.Query[0].expressions, */
                liveness: result.liveness
            }
        } else {
            face = {
                match: false,
                gender: "Indeterminado",
                age: 0,
                expressions: {
                    happy: 0.0
                },
                liveness: "No superada"
            };
        }//liveness
        localStorage.setItem("face", JSON.stringify(face));
    };


    stopRecord = () => {
        //this.player.record().stop();
    };

    stopAnimation = () => {
        this.state.token = null;
    };

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                if (mediaStream) mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("adios 🐶");
        });
    }

    sendData = (match) => {
        statusData("captura_video_token", { coincidencia: match, speech: true })
    }

    statusCancelado = async () => {
        //console.log("entre a status cancelar");
        cancelarRequest();
        localStorage.setItem('flag', 'cancelado');
        this.setState({ isComplete: true, hasResponse: false});
        setTimeout(() => {
            //this.props.history.push("/" + this.state.apikey);
            window.location.href = "/";
            this.setState({ isComplete: false, hasResponse: true });
        }, 300);
    }

    printKaraoke = (phrase) => {
        return (<Fragment>
            {
                phrase.split("").map((element, index) => {
                    return <Typist
                        key={index + "typist"}
                        className="characterToken"
                        cursor={{
                            show: true,
                            blink: false,
                            element: '',
                            hideWhenDone: true,
                            hideWhenDoneDelay: 100,
                        }}>
                        <Typist.Delay ms={(index + 1) * 1000} />
                        <span className="mid_p"><b>{element}</b></span>
                    </Typist>
                })
            }
        </Fragment >)
    }

    checkCamera = () => {
        setTimeout(() => {
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    stopProcess = () => {
        evento('Captura Video Token', 'Click', { description: 'CANCELAR' }, true);
        this.setState({ cancelVideoToken: true });
    }

    statusR = async () => {
        await statusReintento("Captura Video Token", this.state.errorF, "RETRY");
    }

    msjsError = (mensaje) => {
        let eMessage = null
        switch (mensaje) {
            case 'No se encontro rostro':
                eMessage = msjRostro
                break;
            case 'Se detecto cubrebocas':
                eMessage = msjCubrebocas
                break;
            case 'Condiciones de luz no adecuadas':
                eMessage = msjLuz
                break;
            case 'Imagen borrosa':
                eMessage = msjBorrosa
                break;
            case 'Se detectaron multiples caras':
                eMessage = msjMultiple
                break;
            case 'No logramos entender la frase, intente nuevamente':
                eMessage = msjFrase
                break;
            case 'Falta de luz':
                eMessage = msjObscuridad
                break;
            case 'Error frase':
                eMessage = msjFrase2
                break;
            case 'No hay coincidencia intente nuevamente':
                eMessage = msjErrorEmbedings;
                this.setState({ retry: false });
                break;
            case 'Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual':
                eMessage = mensaje
                break;
            case 'Spoof detected':
                eMessage = msjSpoof
                break;
            default:
                eMessage = msjRostro;
                break;
        }
        return eMessage;
    }

    contador = () => {
        var cont = 0
        let intervalo = setInterval(() => {
            cont++;
            if (!this.state.hasResponse) {
                if (cont > 50) {
                    if (this.state.suportMediaRecorder) {
                        cancelarRequest();
                    }
                    clearInterval(intervalo);
                    this.setState({ errorMessage: msjError, errorToken: true, errorF: "Error en el procesamiento de video", token: null, loading: false, hasResponse: true });
                    let video = document.getElementById('video_wrt_environment').classList.remove("blur_video");
                    if (video) {
                        video.classList.remove("blur_video");
                    } else {
                        return
                    }
                    evento('Captura Video Token', 'Error', { status: 'TIMEOUT SERVER' }, false);
                }
            } else {
                clearInterval(intervalo);
            }
        }, 1000);
    }

    reiniciarFlujo = () => {
        evento('Captura Video Token', 'Click', { description: 'REINICIAR', status: msjErrorEmbedings }, false);
        this.finalizarFlujo({ message: 'Se reinicio el flujo porque se detectaron inconsistencias' });
        setTimeout(() => {
            //this.props.history.push("/" + this.state.apikey);
            window.location.href = "/";
        }, 500);
    }

    finalizarFlujo = (respuesta) => {
        relativeTimeRounding
    }

    convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    permisoCamara = async () => {
        const that = this;
        this.setState({errorPermiso: false})
        this.localStream = window.navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        }).catch(
            (error) => {
                if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
                    //required track is missing 
                    evento('Captura Video Token', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
                } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
                    //webcam or mic are already in use 
                    evento('Captura Video Token', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
                } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
                    //constraints can not be satisfied by avb. devices 
                    evento('Captura Video Token', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
                } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                    //permission denied in browser 
                    evento('Captura Video Token', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true);
                    that.setState({errorPermiso: true})
                    console.log("errorPermisothat",that.state.errorPermiso);
                } else if (error.name == "TypeError" || error.name == "TypeError") {
                    //empty constraints object 
                    evento('Captura Video Token', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true);
                } else {
                    //other errors 
                    evento('Captura Video Token', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
                }
                that.setState({ errorVideo: true, sendOTP: false });
            }
        );
        let component = this;
        await this.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    component.setState({ streamStarted: true, errorVideo: false })
                    if (window.MediaRecorder) {
                        //console.log('Soporta MediaRecorder');
                        let mymeType = null
                        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                            mymeType = 'video/webm; codecs=vp9';
                        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                            mymeType = 'video/webm; codecs=vp8';
                        }
                        let type = isIOS ? 'video/mp4' : mymeType;
                        const optionNormal = {
                            type: 'video',
                            mimeType: type,
                        };

                        component.recorder = new RecordRTC(mediaStream, optionNormal);
                    } else {
                        //console.log('No soporta MediaRecorder');
                        component.setState({ suportMediaRecorder: false });
                    }
                } else {
                    component.setState({ errorVideo: true });
                    evento('Captura Video Token', 'Step', { status: 'ERROR GET VIDEO TRACKS' }, true);
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
            console.log("errorPermiso",component.state.errorPermiso);
            if(!component.state.errorPermiso){
                component.obtenerToken(false);
            }else{
                component.setState({ loading: false})
                component.setState({ isStart: false })
            }
        }).catch(e => {
            console.trace(e);
            component.setState({ errorVideo: true })
        });
    }

    finishLifCycle = async (response) => {
        localStorage.setItem("bloqueo", "true");
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        let res = this.convertdataJSON();
        let event_t = await JSON.stringify(res);
        var json_final = {};
        let status = "FAIL"
        json_final = {
            "status": status,
            "step": "finished",
            "description": event_t,
            'response': response,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await flowIncompleted({ 'step': 'captura_video_token', 'event': event }, 'red');
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <div>
                {(this.state.errorToken && this.state.intents < Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) ?
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><b>Lo sentimos</b></h5>
                                </div>
                                <div className="modal-body">
                                    <p>{this.state.errorMessage}</p>
                                </div>
                                <div className="modal-footer">
                                    <Link to="/" className="btn btn-secondary" data-dismiss="modal" onClick={() => sendEventClick('Captura Video Token', 'CANCELAR')}>Cancelar</Link>
                                    {this.state.retry ?
                                        <button type="button" onClick={e => this.obtenerToken(true)} className="btn btn-raised btn-primary main_bg_color">Reintentar</button> :
                                        <button type="button" onClick={this.reiniciarFlujo} className="btn btn-raised btn-primary main_bg_color">Reiniciar</button>}
                                </div>
                            </div>
                        </div>
                    </div> : null}
                <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame speech_mod">
                        <video src="" id="video_wrt_environment" playsInline muted></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradident_overlay"></div>

                    <img className="id_mask animate__animated animate__fadeIn animate__delay-2s" src={process.env.PUBLIC_URL + '/images/face_id_on.svg'} alt="" />
                    <div className="align_alert hide_elmnt"><p>Mantén tu rostro dentro del área definida para iniciar la lectura.</p>
                    </div>
                    <div className="recorder_container">
                        <div className="module_buttons button_cancel_white animate__animated" style={{ position: 'fixed' }}>
                            <button onClick={e => this.stopProcess()} className={["btn btn-primary forcewidth100 main_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={(this.state.sendingOTP) ? true : false}>
                                CANCELAR</button>
                        </div>
                    </div>
                </div>
                {(this.state.isStart && !this.state.showHelp) ? <Fragment>
                    <div className="hollo_gif_bx show_id_hold" style={{ backgroundImage: 'linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%)', overflowY: 'scroll' }}>
                        {(this.state.showCancel) ?
                            <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Cancelar proceso</h5>
                                            <button onClick={e => this.setState({ showCancel: false })} className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={e => { this.setState({ showCancel: false }); sendEventClick('Captura Video Token', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                            <button type="button" onClick={e => this.statusCancelado()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                                        </div>
                                    </div>
                                </div>
                            </div> : ("")}

                        <Header ruta="/video_token" show={() => this.setState({ showHelp: true })} />

                        <div className="main_text_container text-left custom_padding">
                            <h1 className="animate__animated text-left">Confirmación de identidad</h1>
                            <p>Mantén tu <b>rostro visible, sin más personas</b> en la toma y <b>asegúrate</b> de lo siguiente:<br /></p>
                            <br></br>
                            <div className="items_video_token">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><img src="images/bnw/selfie_no_glasses.svg" height="64" alt="" /></td>
                                            <td><p className="instructions_video_token">No utilices lentes</p></td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/bnw/selfie_no_caps.svg" height="64" alt="" /></td>
                                            <td><p className="instructions_video_token">No utilices gorras</p></td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/bnw/selfie_no_face_mask.svg" height="64" alt="" /></td>
                                            <td><p className="instructions_video_token">Retira tu cubreboca</p></td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/bnw/selfie_no_back_light.svg" height="64" alt="" /></td>
                                            <td><p className="instructions_video_token">Evita colocarte a contraluz</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="action_buttons animate__animated animate__fadeIn animate__delay-1s custom_padding">
                            {/* <button type="button" onClick={e => this.obtenerToken(false)} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CONTINUAR</button> */}
                            <button type="button" onClick={e => { this.setState({ sendOTP: true }); this.permisoCamara() }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" disabled={this.state.sendOTP ? true : false}>CONTINUAR</button>
                            <button onClick={e => { this.setState({ showCancel: true }); sendEventClick('Captura Video Token', 'CANCELAR', {}); }} className="btn btn-primary forcewidth100 main_color">
                                CANCELAR</button>
                        </div>

                    </div>
                </Fragment> : ("")}

                <div id="cuadro" className="animate__animated animate__slideInDown delay-3s">
                    <div id="head_shop" className="txt_videotoken" >
                    </div>
                </div>
                {this.state.stable &&
                    <div id="div-stable" className="animate__animated animate__fadeIn">
                        <div className="sprite_stay_b animado " style={{ top: "20%" }}></div>
                    </div>}
                {this.state.intents >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")) ?
                    (<div className="err_finale  animate__animated animate__slideInUp">
                        <div className="center_checks">
                            <h5>Proceso no exitoso</h5>
                            <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                            <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuidTrx).split("-")[0]}</b> </p>
                            <br />
                            {
                                (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") ?
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <p><b>Llámanos al:</b></p>
                                                <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                            </div>
                                            <div className="col">
                                                <p><b>Escríbenos a:</b></p>
                                                <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className="action_buttons noscroll_screen">
                                <Link to={"/"} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                            </div>
                        </div>
                    </div>) : ("")}
                {(this.state.isComplete && !this.state.hasResponse) && <Loader />}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>) : ("")
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            {
                                (!this.state.errorPermiso) ?
                                    <p className="animate__animated animate__slideInDown">Hemos detectado que la  <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>. Por favor, asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                                    :
                                    (isIOS) ?
                                        <p className="animate__animated animate__slideInDown">Por favor <b>acepta los permisos</b> de tu navegador y da click en volver a intentar para continuar.</p>
                                        :
                                        <p className="animate__animated animate__slideInDown">Revisa las instrucciones para reestablcer los <b>permisos de tu navegador</b> y da click en volver a intentar para continuar.
                                            <img src="images/permisos.gif" className="imgPermisos" alt="Reestablecer permisos" />
                                        </p>
                            }
                            <button type="button" onClick={(e) => {
                                if (this.state.errorPermiso) {
                                    window.location.reload();
                                } else {
                                    this.setState({ errorVideo: false })
                                    this.permisoCamara();
                                }
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.cancelVideoToken) &&
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Cancelar proceso</h5>
                                    <button onClick={e => this.setState({ cancelVideoToken: false })} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={e => { this.setState({ cancelVideoToken: false }); sendEventClick('Captura Video Token', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                    <button type="button" onClick={e => this.statusCancelado()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}
CameraVideoTokenIOS = withOrientationChange(CameraVideoTokenIOS);
export default CameraVideoTokenIOS;