import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { hayExcepcion, agregarInfo, status, statusData, esNumLetras, esSoloLetras, esNumeros, esEditable } from '../services/data'
import Ayuda from './ayuda';
import FormularioIne from './forms/formulario_ine';
import FormularioPasaporte from './forms/formulario_pasaporte';

let ResultadosIdentificacion = (props) => {
    const history = useNavigate();
    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [nombre, setNombre] = useState('')
    const [curp, setCurp] = useState('')
    const [focusCurp, setFocusCurp] = useState(false)
    const [vigencia, setVigencia] = useState('')
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false)
    const [esVigente, setEsVigente] = useState(true)
    const [datosCorrectos, setdatosCorrectos] = useState(true)
    const [esCLaboral, setCLaboral] = useState(true)
    const [noOCR, setNoOCR] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [esPermanente, setEsPermanente] = useState(false)
    const [esMigratoria, setEsMigratoria] = useState(false)
    const [showHelp, setShowHelp] = useState(false);
    const [esIne, setEsIne] = useState(false);
    const [esIdArgentino, setIdArgentino] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        if (hayExcepcion()) {
            setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            if (ocrFront.identificacionElectoral)
                setEsIne(true);
            if (ocrFront.identificacionPasaporte)
                setEsPassport(true)
            if (ocrFront.identificacionMigratoria)
                setEsMigratoria(true)
        } else {
            setIdArgentino(true);
        }
        status("datos_personales_ocr_page", "Datos personales OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        if (noOCR) {
            setdatosCorrectos(true)
        } else {
            if (curp && nombre) {
                if (hayExcepcion()) {
                    if (curp.length < 3 || nombre.length < 10) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                } else {
                    if (curp.length < 16 || curp.length > 18 || nombre.length < 10) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                }
            } else {
                setdatosCorrectos(false)
            }
        }
        //console.log("DC:",datosCorrectos);
    }, [curp, nombre])

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    return (
        <Fragment>
            <div className="main_gradient">
                <Header ruta="resultados_identificacion" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animate__animated">Verifica tu información</h1>
                    <p className="animate__animated">Revisa que tus datos coincidan con los de tu identificación.</p>
                </div>
                {(obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "false") === "true" && esIne) ?
                    <FormularioIne />
                    : (esIne) ?
                        <FormularioIne />
                        : hayExcepcion() ?
                            <FormularioIne />
                            : (esPassport || esMigratoria) ?
                                <FormularioPasaporte />
                                : <FormularioIne />
                }
                
                {(!esVigente) ? (<div className="err_finale  animate__animated animate__slideInUp">
                    <div className="center_checks">
                        <h5>Identificación no valida </h5>
                        <p>La <b>vigencia</b> de tu identificación <b>{vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                        </p>
                        <br />
                        <div className="action_buttons noscroll_screen">
                            <Link to={{
                                pathname: "/" + apiKey
                            }} className="btn btn-secondary forcewidth100">TERMINAR</Link>
                            <Link to={{
                                pathname: "/identificacion",
                                state: { passport: esPassport }
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                        </div>
                    </div>
                </div>) : ("")}
                <Footer />
            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )
}

export default ResultadosIdentificacion