import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { isMobile } from 'react-device-detect';
import Loader from "../components/loader";
import { obtenerStatus } from '../services/api';
import { obtenerValorConfig } from '../services/configStyle'
//var intervalFinalizar;

const Finalizado = () => {
    const history = useNavigate();

    const [trx, setTrx] = useState('')
    const [score, setScore] = useState("")
    const [curp, setCurp] = useState("")
    const [loading, setLoading] = useState(false);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [img, setImg] = useState("");
    const [correcto, setCorrecto] = useState(false);

    useEffect(() => {
        let trxB = localStorage.getItem("trx")
        if (trxB !== null) {
            setTrx(trxB)
        }
        setLoading(true)
        let image = localStorage.getItem("img_selfie_lv")
        if (image !== null) {
            setImg(image)
        }
        let correcto = localStorage.getItem("result")
        if (correcto !== null) {
            if (correcto == "1") {
                setCorrecto(true)
            }
            else{
                setCorrecto(false)
            }
        }
        obtenerStatus(trxB).then((response) => {
                if (response.status === 200) {
                    //evento('Captura Video Token', 'Success', informacion, true);
                    console.log("response",response);
                    if(response.data.score.result_text){
                        setLoading(false)
                        setScore(response.data.score.result_text)
                        setCurp(response.data.query.curp)
                    }else{
                        setTimeout(() => {
                            revisarResultado(trxB)
                        }, 100);
                    }
                }
            })
            .catch((error) => {
                console.log("Error:", error);
                setLoading(false);
                let video = document.getElementById('video_wrt');
                if (video) {
                    video.classList.remove("blur_video");
                } else {
                    return;
                }
            })
    }, [])

    const revisarResultado = async (trxB) => {
        //console.log("entrando a revisar status");
        let intervalFinalizar = setInterval(() => {
            obtenerStatus(trxB).then(async (response) => {
                console.log("Esperando...", intervalFinalizar);
                if (response.status === 200) {
                    if(response.data.score.result_text){
                        clearInterval(intervalFinalizar)
                        setLoading(false)
                        setScore(response.data.score.result_text)
                    }else if(response.data.errors[0].description_error) {
                        clearInterval(intervalFinalizar)
                        setLoading(false)
                        setScore(response.data.errors[0].description_error)
                    }
                }
            }).catch((e) => {
                console.log("algun error " + e);
            })
        }, 2000);
    }

    const limpiar = () => {
        setLoading(false)
        history('/')
    }
    
    useEffect(() => {
        console.log("score", score);
    }, [score])

    return (
        <div className="main_gradient ">
            <Header />
            <div className="main_text_container text-left custom_padding animate__animated animate__fadeIn animate__delay-1s">
                            <div className='conteiner_instruccions-alineacion'>
                                <div className='container_instruccions-alinacion-texto'>
                                {correcto?<>
                                <p style={{fontSize:"48px", color: obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_ACENTUACION","")}} >
                                Muy bien, 🤓</p>
                                <p style={{fontSize:"32px", color:"#2D2D2D"}}>completamos tu </p>
                                <p style={{fontSize:"24px"}}><label style={{fontSize:"48px"}}>validación,</label> </p>
                                <p style={{fontSize:"24px", color:"#565656", fontWeight:"400"}}> con éxito! </p>
                                </>
                                :
                                <>
                                <p style={{fontSize:"48px", color: obtenerValorConfig(JSON.parse(localStorage.getItem("dataOtorgante")),"COLOR_ACENTUACION","")}} >
                                Oh no, 🚧</p>
                                <p style={{fontSize:"32px", color:"#2D2D2D"}}>el rostro enviado</p>
                                <p style={{fontSize:"24px"}}><label style={{fontSize:"48px"}}>no tiene</label> </p>
                                <p style={{fontSize:"24px", color:"#565656", fontWeight:"400"}}> coincidencias. </p>
                                </>
                                }
                                </div>
                                <br></br>
                                <div className="contenedor_imagen_final-forma" style={{position:"relative"}}>
                                    <div className="contenedor_imagen_final-figura" style={{position:"relative"}}>
                                        <img src={"data:image/jpg;base64, " + img}  alt="" />
                                        
                                    </div>
                                    <div className="circulo-esquina-finalizar">
                                           { correcto ?
                                           <img src="images/check-fin.svg" alt="" />:
                                            <img src="images/fail-icon.svg" alt=""/>
                                            }
                                        </div>
                                    <p style={{fontSize:"12px", fontWeight:"400", marginTop:"10px"}}>Folio:
                                        <b>{trx.toString().split("-").length > 0 ?trx.toString().split("-")[0]: ""}</b>
                                    </p>
                                </div>
                            
                            </div>
                
            </div>

            <div className="action_buttons noscroll_screen animate__animated animate__fadeIn animate__delay-1s">
                    <button onClick={()=>{
                        history("/")
                    }} className="btn btn-raised btn-primary forcewidth40 main_bg_color" style={{textTransform: "none"}}>
                        Nueva validación
                        <img style={{marginLeft: "20px"}} src="images/return-icon.svg" alt=""/>
                    </button>
            </div>

        </div>
    )
}



export default Finalizado
