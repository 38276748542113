
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';
import moment from 'moment';

let objetoFinal = {};

export const agregarInfo = (dataUser, elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const elementoEncontrado = dataUserLS.findIndex(function (item) {
        return item.description === elemento.description
    })
    ////console.log("elementoEncontrado" + elementoEncontrado + " item:"+elemento);
    if (elementoEncontrado !== -1) {
        dataUserLS[elementoEncontrado] = elemento
    } else {
        dataUserLS.push(elemento)
    }
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const esEditable = () => {
    //lso campos son editables para CDC y otorgantes
    const uuidExcepcion = ["0zmV8ljIWoFfGsaieC4vuEPwpVKYYrAP", "MTMba54LICmL2Boez3KuFdVL1vD1boKk", "7a531a75-e0cf-4158-bd99-86b733ef22ad", "94b502fd-5546-44e1-b12f-2122d410cea0"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    //console.log("respuesta", respuesta);
    return respuesta
}

export const initStatus = (nombreOtorgante) => {
    console.log("entre a initStatus");
    
}

const updateInitStatus = async (nombreOtorgante) => {
    return
}

export const status = (paso, updatepaso) => {
    return
}

export const statusData = (paso, datos) => {
    return
}

export const statusError = (paso, datos, tipo) => {
    console.log("entre a status cancelar");
    return
}

const updateStatus = async (objetoInformacion) => {
    return
}

/* Validaciones */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    let expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    let expreg = /^[A-Za-z-zÀ-ú\s]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    let expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

export const statusReintento = (paso, datos, tipo) => {
    return
}

export const sendData = (objetoStatus) => {
    return
}

export const calculateX = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateY = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const calculateWidth = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);
    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateHeight = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const formatoFechaOcr = (fecha) => {
    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
    let nacimientoC = fecha
    if (fecha.includes(' '))
        nacimientoC = fecha.replaceAll(' ', '/')
    if (moment(nacimientoC, formatosP, true).isValid())
        return nacimientoC;
    else {
        return "";
    }
}

export const formatoFechaCuatroDigitos = (fecha) => {
    let añoActualInt = new Date().getFullYear().toString().substr(-2);
    let añoFechaOcr = fecha.substr(-2);
    let añoFechaOcrInt = fecha.substr(-2);
    let fechaSinAño = fecha.substr(0, 6);
    let fechaOcr = '';
    if (añoFechaOcrInt >= añoActualInt) {
        console.log("entre 19");
        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
    } else {
        let mayoriaDeEdad = añoActualInt - 18;
        if (añoFechaOcrInt <= mayoriaDeEdad) {
            console.log("entre 20");
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        } else {
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        }

    }
    return fechaOcr;
}

export const validarValoresFormulario = (valores) => {
    let validacion = {
        errores: {},
        correcto: true
    };
    Object.entries(valores).forEach(([key, value]) => {
        let campoValidado = validarCampo(value, key);
        if (!campoValidado.correcto) {
            validacion.errores[key] = campoValidado.errores[key];
            validacion.correcto = false;
            return validacion;
        }
    });

    return validacion;
};

export const validarCampo = (valor, campo) => {
    let validacion = {
        errores: {},
        correcto: false
    };
    let formatosFecha = ['DD/MM/YYYY'];
    switch (campo) {
        case "nombreCompleto":
            if (!valor.trim()) {
                validacion.errores.nombreCompleto = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esSoloLetras(valor)) {
                validacion.errores.nombreCompleto = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaNacimiento":
            if (!valor.trim()) {
                validacion.errores.fechaNacimiento = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.fechaNacimiento = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "clave": validacion.correcto = true;
            // if (!valor.trim()) {
            //     validacion.errores.clave = 'Debes ingresar un valor válido';
            // } else if (!esNumLetras(valor) || valor.length < 17 || valor.length > 20) {
            //     validacion.errores.clave = 'Clave de elector no válida';
            // } else {
            //     validacion.correcto = true;
            // }
            break;
        case "fechaRegistro": validacion.correcto = true;
            // if (!valor.trim()) {
            //     validacion.errores.fechaRegistro = 'Debes ingresar un valor válido';
            // } else if (!esNumeros(valor) || valor.length !== 4) {
            //     validacion.errores.fechaRegistro = 'Revisa que el año sea 4 dígitos';
            // } else {
            //     validacion.correcto = true;
            // }
            break;
        case "numeroEmision": validacion.correcto = true;
            // if (!valor.trim()) {
            //     validacion.errores.numeroEmision = 'Debes ingresar un valor válido';
            // } else if (!esNumeros(valor) || valor.length !== 2) {
            //     validacion.errores.numeroEmision = 'Formato de Emisión no válido, 2 dígitos';
            // } else {
            //     validacion.correcto = true;
            // }
            break;
        case "cic": validacion.correcto = true;
            // if (!valor.trim() || valor === "") {
            //     validacion.errores.cic = 'Debes ingresar un valor válido';
            // } else if (!esNumeros(valor) || valor.length < 8 || valor.length > 13) {
            //     validacion.errores.cic = 'Formato de CIC/OCR no válido';
            // } else {
            //     validacion.correcto = true;
            // }
            break;
        case "curp":
            if (!valor.trim()) {
                validacion.errores.curp = 'Debes ingresar un valor válido';
            } else if (valor.length < 16 || !esNumLetras(valor) || valor.length > 18) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigencia":
            if (!valor.trim()) {
                validacion.errores.vigencia = 'Debes ingresar un valor válido';
            } else if ((valor.length !== 4)) {
                validacion.errores.vigencia = 'Revisa que el año sea 4 dígitos';
            } else if (!esNumeros(valor)) {
                validacion.errores.vigencia = 'Formato de vigencia no válido';
            }
            /* let today = new Date();
            let year = today.getFullYear(); 
            else if (Number(valor) < year) {
                validacion.errores.vigencia = 'Vigencia de identificación no válida';
            }  */
            else {
                validacion.correcto = true;
            }
            break;
        case "vigenciaPasaporte":
            if (!valor.trim()) {
                validacion.errores.vigenciaPasaporte = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.vigenciaPasaporte = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "dni":
            if (!valor.trim()) {
                validacion.errores.dni = 'Debes ingresar un valor válido';
            } else if (valor.length < 3 || !esNumLetras(valor)) {
                validacion.errores.dni = 'Formato de DNI no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostal":
            if (!valor.trim()) {
                validacion.errores.codigoPostal = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.codigoPostal = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "pais":
            if (!valor.trim()) {
                validacion.errores.pais = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estado":
            if (!valor.trim()) {
                validacion.errores.estado = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "delegacion":
            if (!valor.trim()) {
                validacion.errores.delegacion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "colonia":
            if (!valor.trim()) {
                validacion.errores.colonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "otraColonia":
            if (!valor.trim()) {
                validacion.errores.otraColonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calle":
            if (!valor.trim()) {
                validacion.errores.calle = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroExterior":
            if (!valor.trim()) {
                validacion.errores.numeroExterior = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroInterior":
            validacion.correcto = true;
            break;
        default:
            validacion.correcto = true
            break;
    }
    //console.log("validacion",validacion);
    return validacion;
};

export const evento = (paso, tipo, data, finished) => {
    
    return
}


export const getX = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424 || innerWidth < 424) {
        return 2;
    } else {
        return 2;
    }
}

export const getY = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth > 424) {
        return 24;
    } else if (innerWidth === 424) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 29;
        }
    } else if (innerWidth >= 412) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 32;
        }
    } else if (innerWidth >= 360) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 27;
        }
    } else {
        return 27;
    }
}

export const getWidth = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 97;
    } else if (innerWidth >= 412) {
        return 96;
    } else if (innerWidth >= 360) {
        return 96;
    } else {
        return 96;
    }
}

export const getHeight = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 45;
    } else if (innerWidth >= 412) {
        return 38;
    } else if (innerWidth >= 360) {
        if (innerHeight <= 640) {
            return 45;
        } else if (innerHeight <= 657) {
            return 37;
        } else if (innerHeight <= 664) {
            return 45;
        } else {
            return 35;
        }
    } else {
        return 35;
    }
}

export const flowIncompleted = async (objetoInformacion, flag) => {
    return
}

export const setFlagStatus = async (objetoInformacion, flag) => {
    return
}

export const sendZip = async (tipoEnvio, bandera) => {
    return
}

export const sendEventClick = (paso, description, objectInformation = {}) => {
    return
}

export const sendImage = async (imagen, modelo, nombre, tipoImagen, lado) => {
    return
}

export const validarOtorganteCDC = () => {
    let otorganteCDC = false;
    const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
    const uuidOtorgante = localStorage.getItem("uuidOtorgante");
    const enable_send_mail = obtenerValorConfig(dataOtorgante, 'ENABLE_SEND_MAIL', "");
    const validacionCorreoCDC = (enable_send_mail && enable_send_mail === 'False') ? true : false;

    if (uuidOtorgante === 'e89ba843-66e4-4f8f-92ae-a148fb751dba' || validacionCorreoCDC) {
        otorganteCDC = true;
    }
    return otorganteCDC;
}

export const sendZipFlag = async (tipoEnvio, bandera, isIOS) => {
    return
}

/* Video token pdf */
export const enviarVideoTokenPdf = (facefile, Acreated, Aexpire, isIOS, service_call)=>{
    return
}

