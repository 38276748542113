import axios from 'axios';


const host = process.env.REACT_APP_URL || "https://api.devdicio.net:8444"
const servicio = process.env.REACT_APP_SERVICE || "dev_"

const endpoints = {
    validateRegistry: `/v1/dev_webhook_workflows/webhook/initial_main_search_rekognition`,
    getStatus: `/v1/dev_webhook_workflows/webhook/status_transaction?transaccion=`,
    otorgante: `${host}/v1/sec_${servicio}params_pk`,
    otp: `${host}/v1/sec_${servicio}otp`,
    newVideoToken: `${host}/v1/sec_${servicio}video_token`,
    getFaceTecConfiguration:`${host}/v1/sec_${servicio}celd_middleware/zoomController/getConfiguration`,
    credenciales_facetec_mw: `${host}/v1/sec_${servicio}util_service/token_creator?iss=ALLIANCE&aud=DICIO&project=CELD`
};

let CancelToken = axios.CancelToken;
let cancel = () => {};
const api_key = "8rQjNygyyhMAK5p6Ya2jBbkoIbQL9IM7"

export function validarRegistro(curpValue, foto) {
    let nombre = "frame_"+ new Date().getTime()+".jpeg"
    var formdata = new FormData();
    formdata.append("curp", curpValue);
    formdata.append("imageQuery", foto, nombre);
    formdata.append("nodes_for_vote", "3");
    return axios({
        url: localStorage.getItem('hostNode') + endpoints.validateRegistry,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'apikey': api_key
        },
        data: formdata
    })
}

export function obtenerStatus(idTrx) {
    return axios({
        url: localStorage.getItem('hostNode') + endpoints.getStatus + idTrx,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': api_key
        },
    })
}

export function obtenerParametrosOtorgante(idOtorgante) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}


export function enviarImagen(uuid, file) {
    var data = new FormData();
    data.append("file", file, (new Date().getTime()) + "_frame.jpg");
    data.append("uuid", uuid);
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.otp,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function getDocuments (){

}

export function cancelarRequest() {
    cancel();
}

// Enviar video
export function enviarVideoRostro(facefile, Acreated, Aexpire, isIOS) {
    let so = isIOS ? 'ios' : 'android';
    var data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);
    data.append("service_call", "videotoken-embedding");
    const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
    let passFlag = "false"
    if (ocrFront != null) {
        if (ocrFront.identificacionPasaporte) {
            passFlag = "true"
        }
    }
    data.append("identificacion_pasaporte", passFlag);
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);
    console.log("llene el form:", VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        url: endpoints.newVideoToken,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}
export var obtenerTokeMidleWareCredencialesFaceTec = async () => {
    let tokenMWFaceTec = false;
    try {
      let res = await fetch(endpoints.credenciales_facetec_mw,{
        method: 'GET',
      });
      tokenMWFaceTec = await res.text();
    } 
    catch (error) {
      this.getError({mensaje:"Error Token MD", error: error.toString()})
    }
    return tokenMWFaceTec;
    
  }
  export async function obtenerllavesFaceTec(tokenMWFaceTec){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ tokenMWFaceTec);
    let res = await fetch(endpoints.getFaceTecConfiguration, 
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    )

    return res.json();
     
  }