import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import Inicio from './pages/inicio'
import Terminos from './pages/terminos'
import CapturePhoto from './pages/capturePhoto'
import IndexComponenteCapturaIdentificacion from './components/captura_identificacion/index';
import ResultadosIdentificacion from './pages/resultados_identificacion';
import CameraVideoTokenIOS from './pages/cameravideotoken_ios';
import Finalizado from './pages/finalizado'
import CURP from './pages/curp';

const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/finalizado" element={<Finalizado />}></Route>
      </Routes>
      <Routes>
        <Route path="/capturePhoto" element={<CapturePhoto />}></Route>
      </Routes>
      <Routes>
        <Route path="/identificacion" element={<IndexComponenteCapturaIdentificacion />}></Route>
      </Routes>
      <Routes>
        <Route path="/resultados_identificacion" element={<ResultadosIdentificacion />}></Route>
      </Routes>
      <Routes>
        <Route path="/video_token" element={<CameraVideoTokenIOS />}></Route>
      </Routes>
      <Routes>
        <Route path="/terminos" element={<Terminos />}></Route>
      </Routes>
      <Routes>
        <Route path="/curp" element={<CURP />}></Route>
      </Routes>
      <Routes>
        <Route path="/" element={<Inicio />}></Route>
      </Routes>
  </BrowserRouter>
  );
}

export default App;
